export class ConfirmationDialogModel {
    public title: string;
    public message: string;
    public okButtonTitle: string;
    public cancelButtonTitle: string;

    constructor(title: string ='', message: string = '', okButtonTitle = 'Save', cancelButtonTitle ='Cancel') {
        this.title = title;
        this.message = message;
        this.okButtonTitle = okButtonTitle;
        this.cancelButtonTitle = cancelButtonTitle;
    }
}