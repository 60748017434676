import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private _auth: AuthService, private router: Router, private loc: Location) { }

  canActivate(): boolean {
    // if (!this._auth.isAuthenticated()) {
    //   this._auth.redirectUrl = this.loc.path();
    //   this.router.navigate(['login']);
    //   return false;
    // } else {
    // this._auth.getProfile()
    return true;
    // }
  }
}

@Injectable({
  providedIn: 'root'
})
export class ClientAuthGuard implements CanActivate {

  constructor(private _auth: AuthService, private router: Router, private loc: Location) { }

  canActivate(): boolean {
    // if (!this._auth.isAuthenticated()) {
    //   this._auth.redirectUrl = this.loc.path();
    //   this.router.navigate(['login']);
    //   return false;
    // } else {
    // this._auth.getProfile()
    return true;
    // }
  }
}

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {

  constructor(private _auth: AuthService, private router: Router, private loc: Location) { }

  canActivate(): boolean {
    // if (!this._auth.isAuthenticated() && !this._auth.isStaff()) {
    //   this._auth.redirectUrl = this.loc.path();
    //   this.router.navigate(['login']);
    //   return false;
    // } else {
    // //   this._auth.getProfile()
    // }
    return true;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ClientLoadGuard implements CanLoad {

  constructor(private _auth: AuthService, private router: Router, private loc: Location) { }

  canLoad(): boolean {
    if (this._auth.isAuthenticated()) {
      return true;
    } else {
      this._auth.redirectUrl = this.loc.path();
      this.router.navigate(['login']);
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class AdminLoadGuard implements CanLoad {

  constructor(private _auth: AuthService, private router: Router, private loc: Location) { }

  canLoad(): boolean {
    if (this._auth.isAuthenticated() && this._auth.isStaff()) {
      return true;
    } else {
      this._auth.redirectUrl = this.loc.path();
      this.router.navigate(['login']);
      return false;
    }
  }
}
