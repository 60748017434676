import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as moment from "moment";
import { ApiService } from './api.service';
import { SITE_URL } from '../../environments/environment';
import { TYPE_CLIENT, TYPE_STAFF, DATE_FORMAT_GRID } from 'src/environments/constants';
import { User, Credential, Company, UserProfile } from '../modules/shared/models/user';
import { SessionIdResult } from '../modules/shared/models/formtrack.http';
import { SignatureFormDetail } from '../modules/shared/models/SignatureForm';
import Bugsnag from '@bugsnag/js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user: User = new User();
  public company: Company = new Company();
  public loading: boolean = false;
  public wrongCredential: boolean = false;
  // public $userSignedIn: Subject<boolean> = new Subject<boolean>();
  public redirectUrl: string = '';

  public companyFetched = new EventEmitter();
  constructor(
    private router: Router,
    private _api: ApiService
  ) {
    this.user = new User();
  }

  submitLoginInfo(credential: Credential) {
    return this._api.post<SessionIdResult>('flexwrapper', 'login', 'submitLoginInfo', credential)
      .subscribe(
        res => {
          this.wrongCredential = false;
          this.user.recordTypeSerial = res.recordType;
          localStorage.clear();
          this.storeSession(res, credential.rememberMe);
          this.getProfile(res, credential.rememberMe).subscribe(
            res => {
              if (this.user.recordTypeSerial != TYPE_CLIENT && this.user.recordTypeSerial != TYPE_STAFF) {
                this.wrongCredential = true;
                this.loading = false;
                this._api.snackBar.open("You do not have permission to log into this portal.", 'Close', {
                  duration: 2500,
                });
                return;
              }
              if (this.isStaff()) {
                if (this.redirectUrl != '') {
                  this.router.navigate([this.redirectUrl]);
                  this.redirectUrl = '';
                }
                else
                  this.router.navigate(['/admin/assignments']);
              }
              else {
                if (this.redirectUrl != '') {
                  this.router.navigate([this.redirectUrl]);
                  this.redirectUrl = '';
                }
                else
                  this.router.navigate(['/client/assignments']);
              }
            }
          );
        },
        err => {
          this.wrongCredential = true;
          this.loading = false;
        }
      )
  }

  submitReloginInfo(credential: Credential) {
    return this._api.post<SessionIdResult>('flexwrapper', 'login', 'submitReloginInfo', credential)
      .subscribe(
        res => {
          this.wrongCredential = false;
          this.user.recordTypeSerial = res.recordType;
          this.storeSession(res, credential.rememberMe);
          this.getProfile(res, credential.rememberMe).subscribe(
            res => { });
          if (this.user.recordTypeSerial != TYPE_CLIENT && this.user.recordTypeSerial != TYPE_STAFF) {
            this.wrongCredential = true;
            this.loading = false;
            this._api.snackBar.open("You do not have permission to log into this portal.", 'Close', {
              duration: 2500,
            });
            return;
          }
          if (this.isStaff()) {
            if (this.redirectUrl != '') {
              this.router.navigate([this.redirectUrl]);
              this.redirectUrl = '';
            }
            else
              this.router.navigate(['/admin/assignments']);
          }
          else {
            if (this.redirectUrl != '') {
              this.router.navigate([this.redirectUrl]);
              this.redirectUrl = '';
            }
            else
              this.router.navigate(['/client/assignments']);
          }
        },
        err => {
          this.wrongCredential = true;
        }
      )
  }

  storeSession(session: any, rememberMe: boolean) {
    if (rememberMe) {
      localStorage.setItem('sessionId', session.sessionId);
      localStorage.setItem('recordTypeSerial', session.recordType);
    } else {
      sessionStorage.setItem('sessionId', session.sessionId);
      sessionStorage.setItem('recordTypeSerial', session.recordType);
    }
  }

  getProfile(session?: any, rememberMe?: boolean, serial?: string) {
    return this._api.get<UserProfile>('greenbank', 'user', 'getLoggedInGreenbankUser', serial).pipe(
      map(
        res => {
          this.user = res.user;
          Bugsnag.setUser(this.user.username, this.user.email, this.user.givenName + ' ' + this.user.surname);
          this.company = res.company;
          if (this.company.imagePath) {
            this.checkImageOrientation();
          }
          if (this.company.dealRateCustomer == 1) {
            if (moment(this.company.dealStartDate).isValid()) {
              this.company.formattedDealStartDate = moment(this.company.dealStartDate).format(DATE_FORMAT_GRID);
            }

            if (moment(this.company.dealEndDate).isValid()) {
              this.company.formattedDealEndDate = moment(this.company.dealEndDate).format(DATE_FORMAT_GRID);
            }

            if (moment(this.company.dealStartDate).isValid() && moment(this.company.dealEndDate).isValid()) {
              if (moment(this.company.dealStartDate).isAfter(moment())) {
                this.company.dealDaysRemaining = moment(this.company.dealEndDate).diff(moment(this.company.dealStartDate), 'days') + 1;
              } else {
                this.company.dealDaysRemaining = moment(this.company.dealEndDate).diff(moment(), 'days') + 1;
              }
              this.company.dealDaysRemaining = this.company.dealDaysRemaining >= 0 ? this.company.dealDaysRemaining : 0
            }
            this.companyFetched.emit(true);
          }
        },
        err => {
        }
      )
    )
  }

  checkImageOrientation() {
    let para = {
      imageOrgPath: SITE_URL + this.company.imagePath
    }
    this._api.get<any>('greenbank', 'setting', 'getImageFormat', para, 'arguments').subscribe(
      res => {
        if (res) {
          this.company.incorrectImageOrientation = true;
        } else {
          this.company.incorrectImageOrientation = false;
        }
      }
    );
  }

  isAuthenticated(): boolean {
    if (sessionStorage.getItem('sessionId') || localStorage.getItem('sessionId')) {
      return true;
    }
    else {
      return false;
    }
  }

  isStaff(): boolean {
    return this.user.recordTypeSerial == TYPE_STAFF || Number(sessionStorage.getItem('recordTypeSerial')) == TYPE_STAFF || Number(localStorage.getItem('recordTypeSerial')) == TYPE_STAFF;
  }

  logout() {
    this.router.navigate(['/login']);
    this._api.post<SessionIdResult>('flexwrapper', 'login', 'logout', '')
      .subscribe(
        res => {
          window.location.reload();
          sessionStorage.removeItem('sessionId');
          localStorage.removeItem('sessionId');
          localStorage.clear();
          this.user = new User();
          this.user.recordTypeSerial = -1;
          // this.$userSignedIn.next(false);
        }
      )
  }

  getSignatureInfo(bodyParams): Observable<any> {
    return this._api.post<SignatureFormDetail>('flexwrapper', 'login', 'getSignatureInfo', bodyParams)
  }

  updateEmailSignature(bodyParams): Observable<any> {
    return this._api.post<SignatureFormDetail>('flexwrapper', 'login', 'updateEmailSignature', bodyParams)
  }
  
}