// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    name: 'test'
};

// Bugsnag API Key
export const BUGSNAG_API_KEY: string = '55765620bf7b51673133674632f218ca';

export const SITE_URL: string = 'https://test-formtrack-api.green-bank.com.au';
export const FORMTRACK_URL: string = 'https://test-formtrack-api.green-bank.com.au/?jsonRequest';
export const FT_POST: string = 'https://test-formtrack-api.green-bank.com.au/';

export const FORMTRACK_FILE_URL: string = 'https://test-formtrack-api.green-bank.com.au/greenbank/file/';
export const FORMTRACK_FILE_URL_DOWNLOAD: string = 'https://test-formtrack-api.green-bank.com.au/greenbank/file/download/';
export const FORMTRACK_FILE_URL_STREAM: string = 'https://test-formtrack-api.green-bank.com.au/greenbank/file/stream/';
export const FORMTRACK_CONTACT_FILE_URL_DOWNLOAD: string = 'https://test-formtrack-api.green-bank.com.au/contacts/file/download/';
export const FORMTRACK_FILE_ZIP_URL_DOWNLOAD: string = 'https://test-formtrack-api.green-bank.com.au/greenbank/file/zipDownload/';
