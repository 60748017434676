import { STATUS, PEOPLESTATUS } from "src/environments/constants";

export class Params {
    public id: string;
    public installerSerial: number;
    public groupSerial: number;
    public installer: string;
    public personSerial: number;
    public accreditation: string;
    public brand: string;
    public model: string;
    public installDate: Date;
    public installOnBuilding: number;
    public assignmentSerial: number;
    public createdBy: number;
    public updatedBy: number;
    public messageTo: number;
    public inboxSerial: number;
    public subject: string;
    public message: string;
    public assignmentType: number;
    public type: number;
    public title: string;
    public text: string;
    public assignmentSerialApprove: number;
    public assignmentSerialStatus: number;
    public fileSerial: number;
    public contactRecordTypeFilter: number;
    public contactSearchTermFilter: string;
    public contactAccreditationFilter: string;
    public contactAccreditationStartFilter: Date;
    public contactAccreditationEndFilter: Date;
    public contactLicenceFilter: string;
    public recordTypeSerial: number;
    public fieldSQL: string;
    public nameFieldSQL: string;
    public assignmentArray: Array<string>;
    public serial: number;
    public statusVerify: number;
    public exportCSVSerials: Array<number>;

    constructor(id?: string, installerSerial?: number, groupSerial?: number, installer?: string, personSerial?: number, accreditation?: string
        , brand?: string, model?: string, installDate?: Date, installOnBuilding?: number, assignmentSerial?: number, createdBy?: number, updatedBy?: number,
        messageTo?: number, inboxSerial?: number, subject?: string, message?: string,
        assignmentType?: number, type?: number, title?: string, text?: string, assignmentSerialApprove?: number, assignmentSerialStatus?: number, fileSerial?: number,
        contactRecordTypeFilter?: number, contactSearchTermFilter?: string, contactAccreditationFilter?: string, contactAccreditationStartFilter?: Date, contactAccreditationEndFilter?: Date, contactLicenceFilter?: string,
        recordTypeSerial?: number, fieldSQL?: string, nameFieldSQL?: string, assignmentArray?: Array<string>, serial?: number,
        statusVerify?: number, exportCSVSerials?: Array<number>
    ) {
        this.id = id;
        this.installerSerial = installerSerial;
        this.groupSerial = groupSerial;
        this.installer = installer;
        this.personSerial = personSerial;
        this.accreditation = accreditation;
        this.brand = brand;
        this.model = model;
        this.installDate = installDate;
        this.installOnBuilding = installOnBuilding;
        this.assignmentSerial = assignmentSerial;
        this.createdBy = createdBy;
        this.updatedBy = updatedBy;
        this.messageTo = messageTo;
        this.inboxSerial = inboxSerial;
        this.subject = subject;
        this.message = message;
        this.assignmentType = assignmentType;
        this.type = type;
        this.title = title;
        this.text = text;
        this.assignmentSerialApprove = assignmentSerialApprove;
        this.assignmentSerialStatus = assignmentSerialStatus;
        this.fileSerial = fileSerial;
        this.contactRecordTypeFilter = contactRecordTypeFilter;
        this.contactSearchTermFilter = contactSearchTermFilter;
        this.contactAccreditationFilter = contactAccreditationFilter;
        this.contactAccreditationStartFilter = contactAccreditationStartFilter;
        this.contactAccreditationEndFilter = contactAccreditationEndFilter;
        this.contactLicenceFilter = contactLicenceFilter;
        this.recordTypeSerial = recordTypeSerial;
        this.fieldSQL = fieldSQL;
        this.nameFieldSQL = nameFieldSQL;
        this.assignmentArray = assignmentArray;
        this.serial = serial;
        this.statusVerify = statusVerify;
        this.exportCSVSerials = exportCSVSerials;
    }
}

export class GridFilter {
    public assignmentList: number = 0;
    public searchTerm: string = "";
    public status: string = "";
    public ownerType: number = 0;
    public assignmentType: number | string = -1;
    public fromDate: string = "";
    public toDate: string = "";
    public receivedFromDate: string = "";
    public receivedToDate: string = "";
    public registryStatus: string = "";
    public paidStatus: string = "";
    public accountManager: number | string = 0;
    public claimType: string = "";
    public serialNumber: string = "";
    public paymentTypes: string = "";
    public createdBy: number = -1;
    public MYOBUpdatedfromDate: string = "";
    public MYOBUpdatedtoDate: string = "";
    public installFromDate: string = "";
    public installToDate: string = "";
    public isolators: number | string = 0;
    public take: number = 100;
    public skip: number = 0;
    public page: number = 1;
    public pageSize: number = 100;
    public myobStatus: string = '';
    public "sort[0][field]": string = "createdDate";
    public "sort[0][dir]": string = "desc";
    public currentPage: number = 1;

    constructor(type?: string, pageSize?: number) {
        if (pageSize) {
            this.pageSize = pageSize;
            this.take = pageSize;
        }
        switch (type) {
            case ('Checking'): {
                this.status = "11";
                this.assignmentList = STATUS.CHECKING;
                this.pageSize = 100;
                this.take = 100;
                break;
            } case ('CSV'): {
                this.status = "12";
                this.assignmentList = STATUS.ALL;
                break;
            } case ('client'): {
                this.accountManager = '';
                this.assignmentList = STATUS.ALL;
                this.pageSize = 250;
                break;
            }
            case ('MYOB'): {
                this.status = "12,13";
                this.assignmentList = STATUS.ALL;
                this.myobStatus = '1';
                this.assignmentList = 0;
                this.assignmentType = -1;
                this.isolators = '';
                this.accountManager = '';
                this.pageSize = 1000;
                this.take = 1000;
                break;
            }
            default: {
                this.status = "";
                this.assignmentList = STATUS.ALL;
                break;
            }
        }
    }
}

export class PeopleFilter {
    public accreditation: string = '';
    public license: string = '';
    public recordType: number = -1;
    public recordTypeSerial: string = "";
    public take: string = '0';
    public accreditationStart: string = '';
    public accreditationEnd: string = '';
    public skip: number = 0;
    public page: number = 1;
    public pageSize: number = 100;
    public searchTerm: string = '';
    public 'sort[0][dir]': string = 'asc';
    public 'sort[0][field]': string = 'name';
    constructor(type?: PEOPLESTATUS) {
        switch (type) {
            case (PEOPLESTATUS.INSTALLER): {
                this.recordType = 3;
                break;
            } case (PEOPLESTATUS.CLIENT): {
                this.recordType = 1;
                break;
            }
            case (PEOPLESTATUS.COMPANY): {
                this.recordType = 4;
                break;
            }
            case (PEOPLESTATUS.STAFF): {
                this.recordType = 2;
                break;
            }
            case (PEOPLESTATUS.ALL):
            default: {
                this.recordType = -1;
                break;
            }
        }
    }
}

export class SearchFilter {
    public take: string;
    public skip: number;
    public page: number;
    public pageSize: number;
    public logic: string;
    public value: string;
    public operator: string;
    public field: string;
    public ignoreCase: boolean;
    public installerType: number;

    constructor(take: string = "50",
        skip: number = 0,
        page: number = 1,
        pageSize: number = 50,
        logic: string = "and",
        value: string = '',
        operator: string = "startswith",
        field: string = "location",
        ignoreCase: boolean = false,
        installerType: number = 0) {
        this.take = take;
        this.skip = skip;
        this.page = page;
        this.pageSize = pageSize;
        this.logic = logic;
        this.value = value;
        this.operator = operator;
        this.field = field;
        this.ignoreCase = ignoreCase;
        this.installerType = installerType;
    }

}

// export class SearchFilterExt extends SearchFilter {
//     public value: string;

//     constructor(take: string = "50",
//     skip: number = 0,
//     page: number = 1,
//     pageSize: number = 50,
//     logic: string = "and",
//     value: string = '',
//     operator: string = "startswith",
//     field: string = "location",
//     ignoreCase: boolean = false,
//     value: string = '') {
//         super();
//         this.value = value;
//     }
// }
