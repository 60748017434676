
export class FormtrackError extends Error {
  public errorCode: number;
  public details: string;
  public data: string;

  constructor(errorCode: number, message: string, details: string, data: string) {
    super(message);
    this.errorCode = errorCode;
    this.details = details;
    this.data = data;
  }
}

export class FormtrackResponse {

  public error: FormtrackError;
  public result: any;

  constructor(error: FormtrackError,  result: any) {
    this.error = error;
    this.result = result;
  }
}

export class SessionIdResult {

  public sessionId: string;
  public personSerial: number;
  public recordType: number;

  constructor() {
    this.sessionId = '';
    this.personSerial = 0;
  }
}