import { FormtrackResponse, FormtrackError } from "./formtrack.http";
import { Moment } from "moment";
import { Deserializable } from "./deserializable.model";
import { SWHAssignmentInstallerAccreditation } from "../../assignment-swh/models/swhassignment-installer.model";

export class CountryModelResponse extends FormtrackResponse {
  public result: Array<Country>;
  constructor(error: FormtrackError, country: Array<Country>) {
    super(error, country);
  }
}

export class CompanyModelResponse extends FormtrackResponse {
  public result: Array<Company>;
  constructor(error: FormtrackError, company: Array<Company>) {
    super(error, company);
  }
}

export class AccessGroupResponse extends FormtrackResponse {
  public result: Array<AccessGroup>;
  constructor(error: FormtrackError, accessGroup: Array<AccessGroup>) {
    super(error, accessGroup);
  }
}


export class Credential {
  public username: string;
  public password: string;
  public rememberMe: boolean;

  constructor(username: string = '', password: string = '', rememberMe: boolean = true) {
    this.username = username;
    this.password = password;
    this.rememberMe = rememberMe;
  }
}

export class UserProfile {
  public user: User;
  public company: Company;

  constructor() {
    this.user = new User();
    this.company = new Company();
  }
}

export class User implements Deserializable {
  public accessGroups: Array<number> = [];
  public personalCategorySerials: any;
  public groupSerial: number = 0;
  public groupName: string = '';
  public personSerial: number = 0;
  public serial: number = 0;
  public recordTypeSerial: number = -4;
  public honorific: string = '';
  public username: string = '';
  public givenName: string = '';
  public surname: string = '';
  public otherNames: string = '';
  public timezone: string = '';
  public email: string = '';
  public phone: string = '';
  public phone2: string = '';
  public fax: string = '';
  public mobile: string = '';
  public unitNumber: string = '';
  public streetNumber: string = '';
  public streetName: string = '';
  public suburb: string = '';
  public stateSerial: number = 0;
  public stateName: any;
  public postcode: string = '';
  public countrySerial: number = 0;
  public createdBy: number = 0;
  public createdDate: Date;
  public lastModifiedBy: string = '';
  public lastModifiedDate: string = '';
  public deleted: string = '';
  public deletedBy: string = '';
  public deletedDate: string = '';
  public password: string = '';
  public confirmPassword: string = '';
  public accessGroupSerials: Array<number> = [];
  public groupSerials: Array<number> = [];
  public accreditation: string = '';
  public licence: string = '';
  public accreditationStart: Moment;
  public accreditationEnd: Moment;
  public accreditationType: any = -1;
  public accreditationPlumbing: string = '';
  public accreditationPlumbingType: any = -1;
  public unitType: number = 0;
  public streetType: number = 0;
  public companies: Array<Company>;
  public groups: Array<AccessGroup>;
  public isNew: boolean = false;
  /*Added new licences*/
  public licencePlumbing: string = '';
  public accreditationPlumbingStart: Moment;
  public accreditationPlumbingEnd: Moment;
  public accreditationSerials: string = '';
  public personAccreditations: Array<SWHAssignmentInstallerAccreditation>;
  public installerClassificationType: string = ''; //What kind of Installer eg: Plumber, Electrician, etc. If applicable

  constructor(isNew?: boolean) {
    this.isNew = isNew;
  }

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class Country {
  public countrySerial: number;
  public countryName: string;
}

export class Company {
  public groupSerial: number;
  public groupName: string;
  public parentSerial: number;
  public recordTypeLabel: string;
  public imagePath: string;
  public imageSerial: string;
  public recordTypeSerial: number;
  public parentName: number;
  public powerPoints: number;
  public dealRateCustomer: number;
  public dealType: number;
  public dealTypeValue: number;
  public volumeType: number;
  public volumeMinimum: number;
  public volumeMaximum: number;
  public dealStartDate: Moment;
  public dealEndDate: Moment;
  public incorrectImageOrientation: boolean;
  public formattedDealStartDate: string = '';
  public formattedDealEndDate: string = '';
  public dealDaysRemaining: number = 0;

  constructor() {
    this.groupSerial = 0;
    this.groupName = '';
    this.parentSerial = 0;
    this.recordTypeLabel = '';
    this.imagePath = '';
    this.imageSerial = '';
    this.recordTypeSerial = 0;
    this.parentName = 0;
    this.powerPoints = 0;
    this.dealRateCustomer = 0;
    this.dealType = 0;
    this.dealTypeValue = 0;
    this.volumeType = 0;
    this.volumeMinimum = 0;
    this.volumeMaximum = 0;
    this.dealStartDate = null;
    this.dealEndDate = null;
  }

}

export class AccessGroup {
  public accessGroupSerial: number;
  public accessGroupName: string;
  public checked: boolean;

}

export class Accreditation {
  public accreditationSerial: number;
  public accreditation: string = '';
  public accreditationType: number = 0;
  public startDate: Moment;
  public expiryDate: Moment;
  public licenseNumber: string = '';
  public isActive: boolean = true;
  public selected: boolean = false;
}
