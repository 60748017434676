import { FormtrackError, FormtrackResponse } from "../../shared/models/formtrack.http";
import { Moment } from "moment";
import { User } from "../../shared/models/user";
import { OwnerType, OWNERTYPE_INDIVIDUAL_ID, STREETTYPE, UNITTYPE, AU_STATES_WITH_SHORTNAME } from "src/environments/constants";
import { Deserializable } from "../../shared/models/deserializable.model";

export class SwhModelResponse extends FormtrackResponse {
  public result: SwhModel;
  constructor(error: FormtrackError, swhModel: SwhModel) {
    super(error, swhModel);
  }
}

export class SWHInstallersModelResponse extends FormtrackResponse {
  public result: Array<SWHInstallersModel>;
  constructor(error: FormtrackError, swhInstallersModel: Array<SWHInstallersModel>) {
    super(error, swhInstallersModel);
  }
}

export class SWHAssignmentItem implements Deserializable {
  public guest: string;
  public assignmentSerial: number;
  public installerSerial: number = 0;
  public exportFileName: string;
  public linkToSerial: number = 0;
  public linkFromSerial: number = 0;
  public createdDate: Date;
  public createdBy: number = 0;
  public updatedDate: Date;
  public updatedBy: number = 0;
  public submittedBy: number;
  public movedToCheckingBy: number;
  public deleted: number;
  public status: number = 0;
  public myobStatus: number;
  public registryStatus: number;
  public exportStatus: number;
  public paidStatus: number;
  public mobileDataSubmittedInstaller: number;
  public mobileDataSubmittedDesigner: number;
  public installOnBuilding: number;
  public paidDate: Date;
  public accreditationNumber: number;
  public gstInvoiceSerial: number = 0;
  public gstInvoiceFileName: string = '';
  public tax1InvoiceSerial: number = 0;
  public tax1InvoiceFileName: string = '';
  public tax2InvoiceSerial: number = 0;
  public tax2InvoiceFileName: string = '';
  public recFormSerial: number = 0;
  public recFormFileName: string = '';


  public nominationFormSignedSerial: number = 0;
  public nominationFormSignedFileName: string = '';

  //START Form Component - Photos and Documents
  public originalUnitsTankLabelPhotoSerial: number = 0;
  public originalUnitsTankLabelPhotoFileName: string = '';
  public originalUnitBeforeDecommissioningPhotoSerial: number = 0;
  public originalUnitBeforeDecommissioningPhotoFileName: string = '';
  public originalUnitOnceRemovedDecommissionedPhotoSerial: number = 0;
  public originalUnitOnceRemovedDecommissionedPhotoFileName: string = '';
  public tankPanelSerialNumberSerial: number = 0;
  public tankPanelSerialNumberFileName: string = '';
  public newUnitInstalledPhotoSerial: number = 0;
  public newUnitInstalledPhotoFileName: string = '';
  public newUnitsTankLabelSerial: number = 0;
  public newUnitsTankLabelFileName: string = '';
  public EFTPOSBankReceiptSerial: number = 0;
  public EFTPOSBankReceiptFileName: string = '';
  public proofAddressSerial: number = 0;
  public signatureSelfiePhotoSerial: number = 0;
  public signatureSelfiePhotoFileName: string = '';
  public plumbingCertificateCompliancePhotoSerial: number = 0;
  public plumbingCertificateCompliancePhotoFileName: string = '';
  public electricalCertificateCompliancePhotoSerial: number = 0;
  public electricalCertificateCompliancePhotoFileName: string = '';
  public invoiceFormSerial: number = 0;
  public invoiceFormFileName: string = '';
  public purchaseSerial: number = 0;
  public purchaseFileName: string = '';
  public additionalDocumentsSerial: number = 0;
  public additionalDocumentsFileName: string = '';
  public additionalDocuments2Serial: number = 0;
  public additionalDocuments2FileName: string = '';
  public additionalDocuments3Serial: number = 0;
  public additionalDocuments3FileName: string = '';
  public compressorPhotoSerial: number = 0;
  public compressorLabelPhotoSerial: number = 0;
  public recyclingReceiptPhotoSerial: number = 0;

  public signatureSelfieMiddleSerial: number = 0;
  public signatureSelfieMiddleFileName: string = '';
  public signatureSelfieEndSerial: number = 0;
  public signatureSelfieEndFileName: string = '';

  //END Form Component - Photos and Documents

  public postImplementationFormSignedSerial: number = 0;
  public postImplementationFormSignedFileName: string = '';

  public electricityBillSerial: number = 0;
  public electricityBillFileName: string = '';
  public STCFormSerial: number = 0;
  public STCFormFileName: string = '';
  public ESCFormSerial: number = 0;
  public ESCFormFileName: string = '';
  public VEECFormSerial: number = 0;
  public VEECFormFileName: string = '';
  public siteAssessmentDeclarationFormSerial: number = 0;
  public siteAssessmentDeclarationFileName: string = '';

  public photoSerial: number = 0;
  public housePhotoSerial: number = 0;
  public photoGPS: string;
  public housePhotoGPS: string;
  public electricalPhotoCertSerial: number = 0;
  public electricalPhotoCertGPS: string;
  public electricalPhotoCertDateTime: string;
  public complianceSerial: number = 0;
  public complianceGPS: string;
  public complianceDateTime: string;
  public purchaseGPS: string;
  public photoDateTime: Date;
  public housePhotoDateTime: Date;
  public purchaseDateTime: Date;
  public ownerType: OwnerType = 0;
  public ownerOrganisationName: string = '';
  public ownerOrganisationType: string = '';
  public ownerFirstName = '';
  public ownerLastName = '';
  public ownerUnitType: number = -1;
  public ownerUnitNumber: string = '';
  public ownerStreetNumber: string = '';
  public ownerStreetName: string = '';
  public ownerStreetType: number = 0;
  public ownerSuburb: string = '';
  public ownerPostcode: string = '';
  public ownerState: number = 0;
  public ownerStateName: string = '';
  public ownerPhoneBH: string = '';
  public ownerPhoneAH: string = '';
  public ownerEmail: string = '';
  public ownerLocation: string = '';
  public installSameAsPostal: number = 0;
  public installPropertyName: string;
  public installPropertyType: number = 0;
  public installStories: number = 0;
  public installUnitType: number = -1;
  public installUnitNumber: string = '';
  public installStreetNumber: string = '';
  public installStreetName: string = '';
  public installStreetType: number = 0;
  public installSuburb: string = '';
  public installPostcode: string = '';
  public installState: number = 0;
  public installGisLocation: string;
  public installLatitude: string = '';
  public installLongitude: string = '';
  public installLocation: string = '';
  public installMultiple: number = 0;
  public installAdditionalInformation: string = '';
  public installPreviousFailed: number = 0;
  public installPreviousFailedNotes: string = '';
  public specialAddress: string = '';
  public installPrevious: number;
  public installPreviousBrand: string;
  public installPreviousYear: Date;
  public brand: string = '';
  public brandModel: BrandModel;
  public model: string = '';
  public modelType: number = -1;
  public systemModelSerial: number = 0;
  public numberPanels: number = 0;
  public capacity: number = 0;
  public installDate: Moment;
  public serialNumber: string = '';
  public isDuplicateTankSerial: boolean = false;
  public purchasedFrom: string;
  public installType: number = -1;
  public existingSystem: number;
  public existingLocation: string;
  public newLocation: string;

  public personSerial: number = 0; //Installer PersonSerial, potentially 
  public electricianID: number = 0; //Electrician PersonSerial Id
  public installerDetail = new User();
  public installerArray: Array<SWHInstallersModel> = []; //The installer collection for the assignment

  //TODO: fix the actual import to bring in the installers as a single array with all installers rather then only a electrician and a plumber
  // Note: We can replace these values by fetching installer detail sepearately using personSerial=>installerDetail
  public installerCompanyName: string;
  public installerAccreditation: string; //CEC Accreditation 'License Value'

  public installerFirstName: string = '';
  public installerLastName: string = '';
  public installerUsername: string = '';
  public installerStateName: string = '';
  public installerUnitType: number = -1;
  public installerUnitNumber: string = '';
  public installerStreetNumber: string = '';
  public installerStreetName: string = '';
  public installerStreetType: number = 0;
  public installerSuburb: string = '';
  public installerPostcode: string;
  public installerState: number = 0;
  public installerPhone: string = '';
  public installerEmail: string = '';
  public installerLicence: string = ''; //CEC Accreditation 'Accreditation Value'
  public installerAccreditationType: string = '';
  public installerPlumbingLicense: string = '';
  public installerPlumbingAccreditation: string = '';
  public installerPlumbingAccreditationType: string = '';

  public electricianCompanyName: string;
  public electricianAccreditation: string;
  public electricianFirstName: string = '';
  public electricianLastName: string = '';
  public electricianUsername: string = '';
  public electricianStateName: string = '';
  public electricianUnitType: number = -1;
  public electricianUnitNumber: string = '';
  public electricianStreetNumber: string = '';
  public electricianStreetName: string = '';
  public electricianStreetType: number = 0;
  public electricianSuburb: string = '';
  public electricianPostcode: string;
  public electricianState: number = 0;
  public electricianPhone: string = '';
  public electricianEmail: string = '';
  public electricianLicence: string = '';

  public paymentType: number = -1;
  public paymentName: string = '';
  public paymentEFT: number = -1;
  public paymentBSB: string = '';
  public paymentAccountName: string = '';
  public paymentAccountNumber: string = '';
  public paymentPersonalUse: number = -1;
  public paymentBusinessName: string = '';
  public paymentABN: string = '';
  public paymentRECs: number = 0;
  public paymentPrice: number = 0.00;
  public paymentReceivedDate: Moment;
  public readTermsAndConditions: number = 0;
  public ownerSignedName: string = '';
  public ownerSignedDate: Moment;
  public ownerSignatureSerial: number = 0;
  public ownerSignedGPS: string;
  public ownerSignature: string;
  public ownerSignedDigitally = 0;
  public installerSignedName: string;
  public installerSignedDate: Moment;
  public installerSignatureSerial: number;
  public installerSignedGPS: string;
  public installerSignature: string;
  public witnessSignedName: string = '';
  public witnessSignedDate: Moment;
  public witnessSignatureSerial: number = 0;
  public witnessSignature: string;
  public searchDetails: string;
  public powerPoints: number;
  public reductionType: number = -1;
  public pricePerSTC: number = 0.00;
  public priceFix: number = 0.00;
  public includeGST: number = 0
  public priceFixIncGST: number = 0.00;
  public priceSTCIncGST: number = 0.00;
  public authorizedSignedName: string;
  public authorizedSignedPosition: string;
  public trusteeName: string;
  public exportCSVClientSts: number;
  public compliancePlateSerial: number = 0;
  public inverterCompliancePlateSerial: number = 0;
  public systemInstallOther: string = '';
  public MYOBUpdatedDate: string = '';
  public accountManager: number = 0;
  public type: number = 0;
  public createdByType: Date;
  public proofAddressFileName: string = '';
  public installStateName: string = '';
  public editableAssignment: boolean = false;
  public isStaff: boolean = false;
  public additionalPhotos: Array<additionalPhoto>;
  public isValidForm: boolean;
  public paymentFormValid: boolean = false;
  public addressFormValid: boolean = false;

  //VEEC/ESS
  public claimingESCorVEEC: boolean = false;
  public paymentESCorVEEC = 0;
  public numberESCorVEEC = 0;
  public tankModelNumber: string; //tbl System Model
  public heatPumpModelNumber: string; //tbl System Model
  public leadGenerationMethod: string; //tbl Assignment
  public numberOfBedrooms: number; //tbl Installation Information
  public buildingOlderThenTwoYears: boolean = true; //tbl Installation Information
  public productMeetsSizingRecommendation: boolean = true; //tbl Installation Information
  public notRecommendedSizeReason: string; //tbl Installation Information
  public volumetricCapacityGreaterThan700: boolean = true; //tbl Installation Information
  public methodOfDecommision: string; //tbl Installation Information
  public methodOfDisposal: string; //tbl Installation Information
  public isVBAComplianceCertificateNumberRequired: boolean = true; //tbl Installation Information
  public vbaComplianceCertificateNumber: string; //tbl Assignment
  public isElectricalSafetyNumberRequired: boolean = true; //tbl Installation Information
  public electricalSafetyNumber: string; //tbl Assignment

  //Electricity changes
  public isHEEREligible: boolean = false;
  public electricityBill = '';
  public electricityBillValue = 0;
  public electricityBillMonthRange = 1;

  public ESCSiteAssesmentFormSignatureSerial: number;
  public ESCSiteAssesmentFormSignedName: string;
  public ESCSiteAssesmentFormSignedDate: Moment;

  public ESCNominationFormSignatureSerial: number;
  public ESCNominationFormSignedName: string;
  public ESCNominationFormSignedDate: Moment;

  public ESCInstallerDeclarationSignatureSerial: number;
  public ESCInstallerDeclarationSignedName: string;
  public ESCInstallerDeclarationSignedDate: Moment;

  public ESCPurchaserDeclarationSignatureSerial: number;
  public ESCPurchaserDeclarationSignedName: string;
  public ESCPurchaserDeclarationSignedDate: Moment;

  public ESCElectricianDeclarationSignatureSerial: number;
  public ESCElectricianDeclarationSignedName: string;
  public ESCElectricianDeclarationSignedDate: Moment;

  public isExistingInstalledLonger: boolean = true;
  public isElectricalWorkRequired: boolean = true;

  //VEEC Installation Legal Forms
  public VEECConsumerDeclarationSerial: number;
  public VEECConsumerDeclarationSignedName: string;
  public VEECConsumerDeclarationSignedDate: Moment;

  public VEECInstallerDeclarationSerial: number;
  public VEECInstallerDeclarationSignedName: string;
  public VEECInstallerDeclarationSignedDate: Moment;

  public incompleteValues: []; //incomplete values for the missing tab
  public isPaperClaim: boolean = false; //Indicates if the assignment is a 'paper claim' submitted by staff
  public paperClaimUploadSerial: number;
  public paperClaimUploadName: string = '';

  //Replaced Unit Information
  public replacedUnitTankSize: number = 0; //tbl systemSolar
  public replacedSystemType: string; //tbl systemSolar
  public replacedUnitTotalRatedCapacity: number; //tbl systemSolar
  public replacedUnitManufacturedDate: Moment; //tbl systemSolar
  public replacedUnitBrand: string; //tbl systemSolar
  public replacedUnitModel: string; //tbl systemSolar

  public replacedUnitManufacturedLabelPhotoSerial: number;
  public replacedUnitManufacturedLabelPhotoFileName: string = '';

  //Form of benefit provided
  public formOfBenefitProvided: string;
  public providedBenefitAmount: string;

  //VEEC Consumer Checklist values
  public consumerReceivedVBAComplianceCertificate: boolean = true;
  public consumerReceivedElectricalSafetyCertificate: boolean = true;
  public customerInformedOfComplianceAndSafetyCertificates: boolean = true;
  public consumerProvidedVEUFactSheet: boolean = true;
  public consumerInformedOfInstalledProductSize: boolean = true;

  // Cold room fields
  public isColdroomActivity: boolean = false;
  public coldroomEligibilityCheck1: boolean = false;
  public coldroomEligibilityCheck2: boolean = false;
  public isColdRoomBelowZero: boolean = false;
  public upgradeRefrigerantType: string = '';
  public coldroomProducts: ColdroomProduct[] = [];
  public coldroomInternalFloorArea: string; //tbl systemSolar
  public actualColdroomInternalFloorArea: number; //tbl systemSolar
  public activityId: number;
  public coldroomStoredProducts: string = '';
  public coldroomInternalFloorSpecificationEvidenceSerial: number = 0;
  public coldroomInternalFloorSpecificationEvidenceFileName: string = '';

  get ownerFullName(): string {
    if (this.ownerType == OWNERTYPE_INDIVIDUAL_ID) {
      return this.ownerFirstName + ' ' + this.ownerLastName;
    }
    return this.ownerOrganisationName;
  }

  private _installationAddress: string;
  get fullInstallationAddress() {
    return this._installationAddress ? this._installationAddress : this.formatInstallationAddress();
  };

  constructor() { }

  deserialize(input: any) {
    Object.assign(this, input);
    console.log('SWHAssignmentItem', this);
    
    this.coldroomProducts = this.isColdroomActivity && input.coldroomProducts && !Array.isArray(input.coldroomProducts) ? JSON.parse(input.coldroomProducts) : [];
    this.installMultiple = Number(input.installMultiple);
    this.installPreviousFailed = Number(input.installPreviousFailed);
    this.claimingESCorVEEC = Boolean(input.claimingESCorVEEC);
    this.ownerType = Number(input.ownerType);
    this.installPropertyType = Number(input.installPropertyType);

    // VEEC legal questions
    this.consumerReceivedVBAComplianceCertificate = input.consumerReceivedVBAComplianceCertificate !== "0";
    this.consumerReceivedElectricalSafetyCertificate = input.consumerReceivedElectricalSafetyCertificate !== "0";
    this.customerInformedOfComplianceAndSafetyCertificates = input.customerInformedOfComplianceAndSafetyCertificates !== "0";
    this.consumerProvidedVEUFactSheet = input.consumerProvidedVEUFactSheet !== "0";
    this.consumerInformedOfInstalledProductSize = input.consumerInformedOfInstalledProductSize !== "0";

    return this;
  }

  formatInstallationAddress(): string {
    var unit = '';
    var street = '';
    var suburb = '';
    var postcode = '';
    var stateAbbreviation = '';
    var unitType = '';
    this._installationAddress = ''
    if (this.installUnitType && 0 < this.installUnitType && this.installUnitType <= STREETTYPE.length) {
      unitType = UNITTYPE.filter(aUnitType => aUnitType.id == this.installUnitType)[0].name;
    }
    unit = unitType + ' ' + this.installUnitNumber;
    if (this.installStreetType && 0 < this.installStreetType && this.installStreetType <= STREETTYPE.length) {
      let streetType = STREETTYPE.filter(aStreetType => aStreetType.id == this.installStreetType)[0].name;
      street = this.installStreetNumber + ' ' + this.installStreetName + ' ' + streetType;
    }
    suburb = this.installSuburb;
    if (this.installState && this.installState > 0) {
      stateAbbreviation = AU_STATES_WITH_SHORTNAME.filter(aState => aState.id == this.installState)[0].abbreviation;
    }
    postcode = this.installPostcode;
    if (unit && unit.trim().length > 0) {
      this._installationAddress = unit + ', ';
    }
    if (street && street.trim().length > 0) {
      this._installationAddress += street + ', ';
    }
    if (suburb && suburb.trim().length > 0) {
      this._installationAddress += suburb + ', ';
    }
    if (stateAbbreviation && stateAbbreviation.trim().length > 0) {
      this._installationAddress += stateAbbreviation + ', ';
    }
    this._installationAddress = this._installationAddress += postcode;
    return this._installationAddress;
  }
}

export class additionalPhoto {
  public additionalPhotoSerial: number;
  public deleted: number;
  public assignmentSerial: number;
  public assignmentType: number;
  public photoSerial: number;
  public photoType: number;
  public photoDateTime: Date;
}

export class BrandModel {
  brandSerial: number;
  brandName: string;
  type: number;
}

export class SystemModel {
  modelSerial: number;
  modelType: number;
  fireRated: number;
  modelName: string;
  numberPanels: number;
  wattsPerPanel: number;
  capacity: number;
  brandSerial: number;
  seriesSerial: number;
  canClaimSTC: number;
  canClaimVEEC: number;
  canClaimESC: number;
  heer_id: number;
  tankSize: number;
  tankModelNumber: string;
  heatPumpModelNumber: string;
}

export class SwhModel {
  modelSerial: number;
  modelName: string;
  fireRated: number;
  numberPanels: number;
  capacity: number;
  seriesSerial: number;
  modelType: number;
}


export class SWHInstallersModel {
  personSerial: number;
  givenName: string;
  surname: string;
  fullNameNice: string;
}

export class InstallActivitiesModel {
  activityID: number;
  activityCode: string;
  activityValue: number;
  activitySubCode: string;
  activityDescription: string;
  activityStatus: number;
  claimType: string;
}

export interface ColdroomProduct {
  productType: ColdroomProductType;
  brand: string;
  model: string;
}

//When updating this list please update the pdf model in backend as well: swhVEEC_ColdroomAssignmentFormHtml
export enum ColdroomProductType {
  ElectronicExpansionValve,
  SuperheatController,
  Compressor,
  CondensingTechnology,
  ECVSDCondenserFan,
  ECEvaporatorFan
}
