export class ReadyToSubmitModel {
    public owner: string;
    public installationAddress: string;
    public serialNumber: string;
    public numberSTC: number;
    public paymentPrice: number;
    public pricePerSTC: string;
    public GST: number;
    public total: number;
    public totalSubmittedRECs: number;
    public assignmentType: number;
    public assignmentSerial: number;

    constructor(owner: string = '', installationAddress: string = '', serialNumber: string = '', numberSTC: number = 0, paymentPrice: number = 0, pricePerSTC: string = '',
        GST: number = 0, total: number = 0, assignmentType: number = 0, assignmentSerial: number = 0, totalSubmittedRECs: number = 0) {
        this.owner = owner;
        this.installationAddress = installationAddress;
        this.serialNumber = serialNumber;
        this.numberSTC = numberSTC;
        this.paymentPrice = paymentPrice;
        this.pricePerSTC = pricePerSTC;
        this.GST = GST;
        this.total = total;
        this.assignmentType = assignmentType;
        this.assignmentSerial = assignmentSerial;
        this.totalSubmittedRECs = totalSubmittedRECs;
    }
}