import { Component, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-postcode-action-buttons',
  template: `<div class="iconized-button">
    <button class="mat-button" (click)='editPostcodeAction()'>
    <i class="material-icons">edit</i> Edit</button>
    <button class="mat-button" (click)="deletePostcodeAction()">
    <i class="material-icons">clear</i> Delete</button></div>`,
  styleUrls: ['./postcode-action-buttons.component.scss']
})

export class PostcodeActionButtonsComponent implements OnInit {

  postcodeId: number;
  public isEditing = false;
  public onEditClicked = new EventEmitter<number>();
  public onDeleteClicked = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {

  }

  // User Interactions

  editPostcodeAction() {
    this.onEditClicked.emit(this.postcodeId);
    this.isEditing = true;
  }

  deletePostcodeAction() {
    this.onDeleteClicked.emit(this.postcodeId);
  }

}
