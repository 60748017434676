import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './components/auth/auth.component';
import { AdminLoadGuard, ClientLoadGuard } from './services/auth-guard.service';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SignatureFormComponent } from './components/signature-form/signature-form.component';
import { InductionFormComponent } from './components/induction-form/induction-form.component';

const routes: Routes = [
  { path: 'login', component: AuthComponent },
  { path: 'signature', component: SignatureFormComponent },
  { path: 'admin', canLoad: [AdminLoadGuard], loadChildren: './modules/admin/admin.module#AdminModule'},
  { path: 'client', canLoad: [ClientLoadGuard], loadChildren: './modules/client/client.module#ClientModule' },
  { path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  { path: 'inductionForm', component: InductionFormComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
