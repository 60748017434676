import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private _auth: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Clone the request to add session params.
        // If user logged in add sessionId to URL
        if (this._auth.isAuthenticated()) {
            let session = localStorage.getItem('sessionId') === null ? sessionStorage.getItem('sessionId') : localStorage.getItem('sessionId');
            
            // Initial implementation of header based authentication. To be done - create 'session' in a basic JWT i.e. { phpsessid: session, timestamp: Date.now() }.
            // let authReq = req.clone({ 
            //     setHeaders: { 
            //         Authorization: session
            //     }

            let authReq = req.clone({ setParams: { 'PHPSESSID': session } });
            return next.handle(authReq);
        }
        else {
            return next.handle(req);
        }
    }
}