export const GREENBANK_PUBLIC_NAME = 'Greenbank Environmental Pty Ltd';

export const SNACKBAR_DURATION: number = 5000;
export const FILTER_DELAY_DURATION = 7200000; //2 hrs

export const DATE_FORMAT: string = 'YYYY-MM-DD';
export const DATETIME_FORMAT: string = 'YYYY-MM-DD hh:mm:ss a';
export const DATE_FORMAT_GRID: string = 'DD/MM/YYYY';
export const DATE_FORMAT_FILTER = 'dd/MM/yyyy';
export const DATE_FORMAT_GRID_WS = 'YYYY-MM-DD';

export const DEFAULT_FILE = '-.txt';

export const DELETE = 'Delete';
export const COPY = 'Copy';
export const SAVE = 'Save';
export const CLOSE = 'Close';

export const INSTALLER_USER = 3;

//installerType
export const INSTALLER_CODE: Array<number> = [1, 4, 5, 7];
export const ELECTRICIAN_CODE: Array<number> = [3, 5, 6, 7];
export const PLUMBER_CODE: Array<number> = [3, 5, 6, 7];
export const DESIGNER_CODE: Array<number> = [2, 4, 6, 7];

export const INSTALLER_SIG = 'Installer';
export const OWNER_SIG = 'Owner';
export const DESIGNER_SIG = 'Designer';
export const WITNESS_SIG = 'Witness';

export const INSTALLATION_MANDATORY_DECLARATION = [
    'I hereby assign the right to create Small-scale technology certificates (STCs) for the above System to GB Environmental Trading Pty Ltd.',
];

export const TERMS_AND_CONDITIONS = [
    'I am the legal owner of the water heater (WH) or the small generation unit (SGU) as describe in this form.'
];

export enum VIEWS {
    ALL,
    CHECKING,
    CSV,
    FILES,
    REPORTS,
    CLIENT,
    MYOB
}

export enum STATUS {
    ALL,
    INCOMPLETE,
    PENDING,
    CHECKING,
    COMPLETE
}

export enum PEOPLESTATUS {
    ALL,
    INSTALLER,
    COMPANY,
    CLIENT,
    STAFF
}

export enum UTILITIES {
    EXPORT,
    IMPORT,
    REFRESH
}

export const STATUS_CHECKING = 11;
export const STATUS_READY_SUBMIT = 15;
export const STATUS_CANCELLED = 16;

export const PVD_NAME = 'Solar PV';
export const SWH_NAME = 'Water Heater';
export const ASSIGNMENT_TYPE_PVD = 1;
export const ASSIGNMENT_TYPE_SWH = 0;

export const TYPE_CLIENT = 1;
export const TYPE_STAFF = 2;
export const TYPE_INSTALLER = 3;
export const TYPE_INSTALLER_COMPANY = 4;

export const PAID_STATUS = [
    { 'id': 0, 'name': 'Not Submitted', 'colour': 'ff5f5f' },
    { 'id': 1, 'name': 'Submitted', 'colour': '7f3f7f' },
    { 'id': 2, 'name': 'Unpaid', 'colour': '3f7fff' },
    { 'id': 3, 'name': 'Paid', 'colour': '007f00' },
    { 'id': 4, 'name': 'Reversed', 'colour': 'af3f3f' }
];

export const REGISTRY_STATUS = [
    { 'id': 0, 'name': '-- No Status --', 'colour': '000000' },
    { 'id': 1, 'name': 'Pending', 'colour': 'df7f5f' },
    { 'id': 2, 'name': 'New', 'colour': '3f7fff' },
    { 'id': 3, 'name': 'In Progress', 'colour': '3f7fff' },
    { 'id': 4, 'name': 'Not Approved', 'colour': 'ff5f5f' },
    { 'id': 5, 'name': 'Pending Payment', 'colour': 'df7f5f' },
    { 'id': 6, 'name': 'Approved', 'colour': '007f00' }
];

export const MYOB_STATUS = [
    { 'id': 0, 'name': '-- No Status --', 'colour': '000000' },
    { 'id': 1, 'name': 'Queued', 'colour': '3f7fff' },
    { 'id': 2, 'name': 'Complete', 'colour': '007f00' }
];

export const ASSIGNMENTS_STATUS = [
    { 'id': 0, 'name': 'Incomplete', 'colour': 'ff5f5f' },
    { 'id': 1, 'name': 'Proof of Purchase', 'colour': 'df7f5f' },
    { 'id': 2, 'name': 'Compliance Certificate', 'colour': 'df7f5f' },
    { 'id': 3, 'name': 'CEC Number', 'colour': 'df7f5f' },
    { 'id': 4, 'name': 'Install Date', 'colour': 'df7f5f' },
    { 'id': 5, 'name': 'Upgrade Information', 'colour': 'df7f5f' },
    { 'id': 6, 'name': 'Phone Number', 'colour': 'df7f5f' },
    { 'id': 7, 'name': 'System Details', 'colour': 'df7f5f' },
    { 'id': 8, 'name': 'Serial Numbers', 'colour': 'df7f5f' },
    { 'id': 9, 'name': 'Installer Type', 'colour': 'df7f5f' },
    { 'id': 10, 'name': 'Rejected', 'colour': 'ff3f00' },
    { 'id': 11, 'name': 'Checking', 'colour': '7f3f7f' },
    { 'id': 12, 'name': 'Queued', 'colour': '3f7fff' },
    { 'id': 13, 'name': 'Complete', 'colour': '007f00' },
    { 'id': 14, 'name': 'Request for Information', 'colour': 'ff0000' },
    { 'id': 15, 'name': 'Ready to Submit', 'colour': '007f00' },
    { 'id': 16, 'name': 'Cancelled', 'colour': 'ff3f00' }
];

export const STATUS_ASSIGNMENT_READYTOSUBMIT_NAME = 'Ready to Submit';

export const OWNERTYPE_NOSTATUS_NAME = '';
export const OWNERTYPE_NOSTATUS_ID = 0;
export const OWNERTYPE_INDIVIDUAL_NAME = 'Individual';
export const OWNERTYPE_INDIVIDUAL_ID = 1;
export const OWNERTYPE_GOVERNMENT_NAME = 'Government body';
export const OWNERTYPE_GOVERNMENT_ID = 2;
export const OWNERTYPE_CORPORATE_NAME = 'Business';
export const OWNERTYPE_CORPORATE_ID = 3;
export const OWNERTYPE_TRUSTEE_NAME = 'Trustee';
export const OWNERTYPE_TRUSTEE_ID = 4;

export const OWNERTYPE = [
    { id: OWNERTYPE_NOSTATUS_ID, name: OWNERTYPE_NOSTATUS_NAME },
    { id: OWNERTYPE_INDIVIDUAL_ID, name: OWNERTYPE_INDIVIDUAL_NAME },
    { id: OWNERTYPE_GOVERNMENT_ID, name: OWNERTYPE_GOVERNMENT_NAME },
    { id: OWNERTYPE_CORPORATE_ID, name: OWNERTYPE_CORPORATE_NAME },
    { id: OWNERTYPE_TRUSTEE_ID, name: OWNERTYPE_TRUSTEE_NAME }
];

export enum OwnerType {
    None,
    Individual,
    Government,
    Corporate,
    Trustee
}

export enum PropertyType {
    Undefined,
    Residential,
    School,
    Commercial
}

export enum NOTE_TYPES {
    Note = 'Note',
    Comment = 'Comment',
    Warning = 'Warning',
    Urgent = 'Urgent Attention'
}

export const NOTE_ID = new Map<string, number>([
    [NOTE_TYPES.Note, 1],
    [NOTE_TYPES.Comment, 2],
    [NOTE_TYPES.Warning, 3],
    [NOTE_TYPES.Urgent, 4]
]);

export const AU_STATES_WITH_SHORTNAME = [
    { id: 0, name: '', abbreviation: '' },
    { id: 1, name: 'Australian Capital Territory', abbreviation: 'ACT' },
    { id: 2, name: 'New South Wales', abbreviation: 'NSW' },
    { id: 3, name: 'Northern Territory', abbreviation: 'NT' },
    { id: 4, name: 'Queensland', abbreviation: 'QLD' },
    { id: 5, name: 'South Australia', abbreviation: 'SA' },
    { id: 6, name: 'Tasmania', abbreviation: 'TAS' },
    { id: 7, name: 'Victoria', abbreviation: 'VIC' },
    { id: 8, name: 'Western Australia', abbreviation: 'WA' }
];

export const GRID_CONNECTED = [
    { id: 0, name: '' },
    { id: 1, name: 'Connected to an electricity grid without battery storage' },
    { id: 2, name: 'Connected to an electricity grid with battery storage' },
    { id: 3, name: 'Stand-alone (not connected to an electricity grid)' }
];

export const SWH_INSTALL_TYPE = [
    { id: 0, name: '' },
    { id: 1, name: 'part of a new building' },
    { id: 2, name: 'replace an electric hot water system' },
    { id: 3, name: 'replace a solar water heater (including boosted solar)' },
    { id: 4, name: 'represent the first installation of a water heater in an existing building' },
    { id: 5, name: 'replace a gas hot water system' },
    { id: 6, name: 'other' }
];

export const PVD_INSTALLATION_TYPES = [
    { id: 1, name: 'New system' },
    { id: 2, name: 'Replacement system' },
    { id: 3, name: 'Extension system' },
    { id: 4, name: 'Additional system' }
];

export const NONUNITTYPES = [
    { id: 11, name: 'PO BOX' },
    { id: 59, name: 'CARE PO' },
    { id: 62, name: 'GPO BOX' },
    { id: 63, name: 'LOCKED BAG' },
    { id: 65, name: 'PRIVATE BAG' }
];

export const UNITTYPE = [
    { id: 0, name: '', canBeInstallation: true },
    { id: 1, name: 'BLOCK', canBeInstallation: true },
    { id: 2, name: 'FLOOR', canBeInstallation: true },
    { id: 3, name: 'GRID', canBeInstallation: true },
    { id: 4, name: 'JETTY', canBeInstallation: true },
    { id: 5, name: 'LOC', canBeInstallation: true },
    { id: 6, name: 'LOT', canBeInstallation: true },
    { id: 7, name: 'RMB', canBeInstallation: false },
    { id: 8, name: 'SECTION', canBeInstallation: true },
    { id: 9, name: 'SITE', canBeInstallation: true },
    { id: 10, name: 'UNIT', canBeInstallation: true },
    { id: 11, name: 'PO BOX', canBeInstallation: false },
    { id: 12, name: 'LEVEL', canBeInstallation: true },
    { id: 13, name: 'SHED', canBeInstallation: true },
    { id: 14, name: 'SHOP', canBeInstallation: true },
    { id: 15, name: 'SE', canBeInstallation: true },
    { id: 16, name: 'ANT', canBeInstallation: true },
    { id: 17, name: 'APT', canBeInstallation: true },
    { id: 18, name: 'ATM', canBeInstallation: true },
    { id: 19, name: 'BBQ', canBeInstallation: true },
    { id: 20, name: 'BTSD', canBeInstallation: true },
    { id: 21, name: 'BLDG', canBeInstallation: true },
    { id: 22, name: 'BNGW', canBeInstallation: true },
    { id: 23, name: 'CAGE', canBeInstallation: true },
    { id: 24, name: 'CARP', canBeInstallation: true },
    { id: 25, name: 'CARS', canBeInstallation: true },
    { id: 26, name: 'CLUB', canBeInstallation: true },
    { id: 27, name: 'COOL', canBeInstallation: true },
    { id: 28, name: 'CTGE', canBeInstallation: true },
    { id: 29, name: 'DUPL', canBeInstallation: true },
    { id: 30, name: 'FCTY', canBeInstallation: true },
    { id: 31, name: 'FLAT', canBeInstallation: true },
    { id: 32, name: 'GRGE', canBeInstallation: true },
    { id: 33, name: 'HALL', canBeInstallation: true },
    { id: 34, name: 'HSE', canBeInstallation: true },
    { id: 35, name: 'KSK', canBeInstallation: true },
    { id: 36, name: 'LSE', canBeInstallation: true },
    { id: 37, name: 'LBBY', canBeInstallation: true },
    { id: 38, name: 'LOFT', canBeInstallation: true },
    { id: 39, name: 'MSNT', canBeInstallation: true },
    { id: 40, name: 'MBTH', canBeInstallation: true },
    { id: 41, name: 'OFFC', canBeInstallation: true },
    { id: 42, name: 'RESV', canBeInstallation: true },
    { id: 43, name: 'ROOM', canBeInstallation: true },
    { id: 44, name: 'SHRM', canBeInstallation: true },
    { id: 45, name: 'SIGN', canBeInstallation: true },
    { id: 46, name: 'STLL', canBeInstallation: true },
    { id: 47, name: 'STOR', canBeInstallation: true },
    { id: 48, name: 'STR', canBeInstallation: true },
    { id: 49, name: 'STU', canBeInstallation: true },
    { id: 50, name: 'SUBS', canBeInstallation: true },
    { id: 51, name: 'TNCY', canBeInstallation: true },
    { id: 52, name: 'TWR', canBeInstallation: true },
    { id: 53, name: 'TNHS', canBeInstallation: true },
    { id: 54, name: 'VLT', canBeInstallation: true },
    { id: 55, name: 'VLLA', canBeInstallation: true },
    { id: 56, name: 'WARD', canBeInstallation: true },
    { id: 57, name: 'WHSE', canBeInstallation: true },
    { id: 58, name: 'WKSH', canBeInstallation: true },
    { id: 59, name: 'CARE PO', canBeInstallation: false },
    { id: 60, name: 'CMA', canBeInstallation: false },
    { id: 61, name: 'CMB', canBeInstallation: false },
    { id: 62, name: 'GPO BOX', canBeInstallation: false },
    { id: 63, name: 'LOCKED BAG', canBeInstallation: false },
    { id: 64, name: 'MS', canBeInstallation: false },
    { id: 65, name: 'PRIVATE BAG', canBeInstallation: false },
    { id: 66, name: 'RSD', canBeInstallation: false },
    { id: 67, name: 'RMS', canBeInstallation: false }
];

export const STREETTYPE = [
    { id: 0, name: '' },
    { id: 1, name: 'Access' },
    { id: 2, name: 'Alley' },
    { id: 3, name: 'Arcade' },
    { id: 4, name: 'Avenue' },
    { id: 5, name: 'Bend' },
    { id: 6, name: 'Boulevard' },
    { id: 8, name: 'Cove' },
    { id: 9, name: 'Chase' },
    { id: 10, name: 'Circle' },
    { id: 11, name: 'Circuit' },
    { id: 12, name: 'Circus' },
    { id: 13, name: 'Close' },
    { id: 14, name: 'Corner' },
    { id: 15, name: 'Court' },
    { id: 16, name: 'Crescent' },
    { id: 17, name: 'Drive' },
    { id: 18, name: 'End' },
    { id: 19, name: 'Entrance' },
    { id: 20, name: 'Esplanade' },
    { id: 21, name: 'Freeway' },
    { id: 22, name: 'Gap' },
    { id: 23, name: 'Gardens' },
    { id: 24, name: 'Glade' },
    { id: 25, name: 'Grove' },
    { id: 26, name: 'Highway' },
    { id: 27, name: 'Hill' },
    { id: 28, name: 'Junction' },
    { id: 29, name: 'Key' },
    { id: 30, name: 'Lane' },
    { id: 31, name: 'Link' },
    { id: 32, name: 'Loop' },
    { id: 33, name: 'Mall' },
    { id: 34, name: 'Motorway' },
    { id: 35, name: 'Outlook' },
    { id: 36, name: 'Parade' },
    { id: 37, name: 'Parkway' },
    { id: 38, name: 'Pass' },
    { id: 39, name: 'Path' },
    { id: 40, name: 'Pathway' },
    { id: 41, name: 'Place' },
    { id: 42, name: 'Plaza' },
    { id: 43, name: 'Promenade' },
    { id: 45, name: 'Rest' },
    { id: 46, name: 'Rise' },
    { id: 47, name: 'Road' },
    { id: 48, name: 'Row' },
    { id: 49, name: 'Square' },
    { id: 50, name: 'Street' },
    { id: 52, name: 'Terrace' },
    { id: 53, name: 'Track' },
    { id: 54, name: 'Vale' },
    { id: 55, name: 'View' },
    { id: 56, name: 'Vista' },
    { id: 57, name: 'Walk' },
    { id: 59, name: 'Way' },
    { id: 60, name: 'Approach' },
    { id: 62, name: 'Gate' },
    { id: 63, name: 'Glen' },
    { id: 64, name: 'Heights' },
    { id: 65, name: 'Meander' },
    { id: 66, name: 'Mews' },
    { id: 67, name: 'Point' },
    { id: 68, name: 'Ramble' },
    { id: 69, name: 'Retreat' },
    { id: 70, name: 'Turn' },
    { id: 72, name: 'Wynd' },
    { id: 73, name: 'Alleyway' },
    { id: 74, name: 'Amble' },
    { id: 75, name: 'Arterial' },
    { id: 76, name: 'Artery' },
    { id: 77, name: 'Banan' },
    { id: 78, name: 'Boardwalk' },
    { id: 79, name: 'Brace' },
    { id: 80, name: 'Brae' },
    { id: 81, name: 'Break' },
    { id: 82, name: 'Brow' },
    { id: 83, name: 'Bypass' },
    { id: 84, name: 'Byway' },
    { id: 85, name: 'Causeway' },
    { id: 86, name: 'Centre' },
    { id: 87, name: 'Concourse' },
    { id: 88, name: 'Copse' },
    { id: 89, name: 'Courtyard' },
    { id: 90, name: 'Crest' },
    { id: 91, name: 'Cross' },
    { id: 92, name: 'Cul-De-Sac' },
    { id: 93, name: 'Cutting' },
    { id: 94, name: 'Dale' },
    { id: 95, name: 'Dip' },
    { id: 96, name: 'Driveway' },
    { id: 97, name: 'Edge' },
    { id: 98, name: 'Elbow' },
    { id: 99, name: 'Expressway' },
    { id: 100, name: 'Fairway' },
    { id: 101, name: 'Follow' },
    { id: 102, name: 'Footway' },
    { id: 103, name: 'Formation' },
    { id: 104, name: 'Frontage' },
    { id: 105, name: 'Grange' },
    { id: 106, name: 'Green' },
    { id: 107, name: 'Highroad' },
    { id: 108, name: 'Interchange' },
    { id: 109, name: 'Laneway' },
    { id: 110, name: 'Line' },
    { id: 111, name: 'Lookout' },
    { id: 112, name: 'Nook' },
    { id: 113, name: 'Passage' },
    { id: 114, name: 'Piazza' },
    { id: 115, name: 'Pocket' },
    { id: 116, name: 'Port' },
    { id: 117, name: 'Quadrant' },
    { id: 118, name: 'Quays' },
    { id: 119, name: 'Ridge' },
    { id: 120, name: 'Rotary' },
    { id: 121, name: 'Route' },
    { id: 122, name: 'Rue' },
    { id: 123, name: 'Serviceway' },
    { id: 124, name: 'Shunt' },
    { id: 125, name: 'Spur' },
    { id: 126, name: 'Subway' },
    { id: 127, name: 'Tarn' },
    { id: 128, name: 'Thoroughfare' },
    { id: 129, name: 'Tollway' },
    { id: 130, name: 'Top' },
    { id: 131, name: 'Tor' },
    { id: 132, name: 'Trail' },
    { id: 133, name: 'Underpass' },
    { id: 134, name: 'Viaduct' },
    { id: 135, name: 'Walkway' },
    { id: 136, name: 'Wharf' }
];

export const PROPERTYTYPE = [
    { id: 0, name: '' },
    { id: 1, name: 'Residential' },
    { id: 2, name: 'School' },
    { id: 3, name: 'Commercial' }
];

export const STORIESTYPE = [
    { id: 0, name: '' },
    { id: 1, name: 'Single story' },
    { id: 2, name: 'Multi story' }
];

export const CLASSIFICATIONS_TYPES = [
    { id: 0, name: 'Installer' },
    { id: 1, name: 'Installer and Designer' },
    { id: 2, name: 'Installer and Electrician' },
    { id: 3, name: 'Installer Designer and Electrician' }
];

export const STREETS_PEOPLE = [
    { id: 0, name: '' },
    { id: 1, name: 'Access' },
    { id: 2, name: 'Alley' },
    { id: 3, name: 'Arcade' },
    { id: 4, name: 'Avenue' },
    { id: 5, name: 'Bend' },
    { id: 6, name: 'Boulevard' },
    { id: 7, name: 'Broadway' },
    { id: 8, name: 'Cove' },
    { id: 9, name: 'Chase' },
    { id: 10, name: 'Circle' },
    { id: 11, name: 'Circuit' },
    { id: 12, name: 'Circus' },
    { id: 13, name: 'Close' },
    { id: 14, name: 'Corner' },
    { id: 15, name: 'Court' },
    { id: 16, name: 'Crescent' },
    { id: 17, name: 'Drive' },
    { id: 18, name: 'End' },
    { id: 19, name: 'Entrance' },
    { id: 20, name: 'Esplanade' },
    { id: 21, name: 'Freeway' },
    { id: 22, name: 'Gap' },
    { id: 23, name: 'Gardens' },
    { id: 24, name: 'Glade' },
    { id: 25, name: 'Grove' },
    { id: 26, name: 'Highway' },
    { id: 27, name: 'Hill' },
    { id: 28, name: 'Junction' },
    { id: 29, name: 'Key' },
    { id: 30, name: 'Lane' },
    { id: 31, name: 'Link' },
    { id: 32, name: 'Loop' },
    { id: 33, name: 'Mall' },
    { id: 34, name: 'Motorway' },
    { id: 35, name: 'Outlook' },
    { id: 36, name: 'Parade' },
    { id: 37, name: 'Parkway' },
    { id: 38, name: 'Pass' },
    { id: 39, name: 'Path' },
    { id: 40, name: 'Pathway' },
    { id: 41, name: 'Place' },
    { id: 42, name: 'Plaza' },
    { id: 43, name: 'Promenade' },
    { id: 44, name: 'Quay' },
    { id: 45, name: 'Rest' },
    { id: 46, name: 'Rise' },
    { id: 47, name: 'Road' },
    { id: 48, name: 'Row' },
    { id: 49, name: 'Square' },
    { id: 50, name: 'Street' },
    { id: 51, name: 'Strip' },
    { id: 52, name: 'Terrace' },
    { id: 53, name: 'Track' },
    { id: 54, name: 'Vale' },
    { id: 55, name: 'View' },
    { id: 56, name: 'Vista' },
    { id: 57, name: 'Walk' },
    { id: 58, name: 'Waters' },
    { id: 59, name: 'Way' },
    { id: 60, name: 'Approach' },
    { id: 61, name: 'Common' },
    { id: 62, name: 'Gate' },
    { id: 63, name: 'Glen' },
    { id: 64, name: 'Heights' },
    { id: 65, name: 'Meander' },
    { id: 66, name: 'Mews' },
    { id: 67, name: 'Point' },
    { id: 68, name: 'Ramble' },
    { id: 69, name: 'Retreat' },
    { id: 71, name: 'Turn' },
    { id: 72, name: 'Village' },
    { id: 73, name: 'Wynd' }
];

export const AUSTALIAN_STATES = [
    { id: 0, name: '' },
    { id: 1, name: 'Australian Capital Territory' },
    { id: 2, name: 'New South Wales' },
    { id: 3, name: 'Northern Territory' },
    { id: 4, name: 'Queensland' },
    { id: 5, name: 'South Australia' },
    { id: 6, name: 'Tasmania' },
    { id: 7, name: 'Victoria' },
    { id: 8, name: 'Western Australia' }
];

export const CLASSIFICATIONS_TYPES_GROUP = [
    { id: 0, name: '' },
    { id: 1, name: 'Installer' },
    { id: 2, name: 'Designer' },
    { id: 3, name: 'Electrician' },
    { id: 4, name: 'Installer and Designer' },
    { id: 5, name: 'Installer and Electrician' },
    { id: 6, name: 'Designer and Electrician' },
    { id: 7, name: 'Installer, Designer and Electrician' }
];
export const CLASSIFICATIONS_TYPES_GROUP_PLUMBER = [
    { id: 0, name: '' },
    { id: 1, name: 'Installer' },
    { id: 2, name: 'Designer' },
    { id: 3, name: 'Plumber' },
    { id: 4, name: 'Installer and Designer' },
    { id: 5, name: 'Installer and Plumber' },
    { id: 6, name: 'Designer and Plumber' },
    { id: 7, name: 'Installer, Designer and Plumber' }
];
//A universal list of Person accreditation types that will be checked to see if user is a certified plumber, electrician, etc
export const ACCREDITATION_TYPES = [
    { id: 0, label: '' },
    { id: 1, label: 'SWH' },
    { id: 2, label: 'Installer' },
    { id: 3, label: 'Designer' },
    { id: 4, label: 'Electrician' },
    { id: 5, label: 'Plumber' },
    { id: 6, label: 'Installer and Designer' },
    { id: 7, label: 'Installer and Electrician' },
    { id: 8, label: 'Installer and Plumber' },
    { id: 9, label: 'Designer and Electrician' },
    { id: 10, label: 'Designer and Plumber' },
    { id: 11, label: 'Installer, Designer, and Plumber' },
    { id: 12, label: 'Installer, Designer, and Electrician' },
    { id: 13, label: 'Installer, Designer, Electrician, and Plumber' },
    { id: 14, label: 'Other'},
    { id: 15, label: 'Refrigeration'}
]

export const ELECTRICIAN_ACCREDITATIONTYPES = [
    4,7,9,12,13
]

// tslint:disable-next-line: max-line-length
export const UNIT_REQ_STREET_NO = [true, false, true, false, false, true, true, false, true, true, true, false, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, false, false, false, false, false, false, false, false, false];
// tslint:disable-next-line: max-line-length
export const UNIT_OPT_STREET_NO = [false, false, false, false, false, true, true, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
// tslint:disable-next-line: max-line-length
export const UNIT_REQ_STREET_NAME = [true, true, true, true, true, true, true, true, true, true, true, false, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, false, false, false, false, false, false, false, false, false];
// tslint:disable-next-line: max-line-length
export const UNIT_OPT_STREET_NAME = [false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];

//Lead Generation methods for use in Assignment dropdowns
export const LEAD_GENERATION_METHODS = [
    { id: 0, name: 'Customer initiated Contact' },
    { id: 1, name: 'Doorknocking' },
    { id: 2, name: 'Email Campaign' },
    { id: 3, name: 'Online Advertisement' },
    { id: 4, name: 'Pamphlet' },
    { id: 5, name: 'Print Advertisement' },
    { id: 6, name: 'Promotional Booth' },
    { id: 7, name: 'Radio advertisement' },
    { id: 8, name: 'Social Media' },
    { id: 9, name: 'Telemarketing ' },
    { id: 10, name: 'TV Advertisement' },
    { id: 11, name: 'Other' }
]

export const USER_HONORIFICS = [
    "Mr","Mrs","Ms","Miss","Dr","Prof","Assoc Prof"
]

//Industry/Business Type Values for corporate clients
export const BUSINESS_INDUSTRY_TYPES = [
    "Accommodation Services",
    "Administrative & Support Services",
    "Agriculture, Forestry and Fishing",
    "Arts and Entertainment- Cinema, Art Gallery & Creative",
    "Construction- Building & Other",
    "Construction- Land & Site Preparation",
    "Education- Community",
    "Education- Preschool, Childcare & Kindergarden",
    "Education- Primary School & High School",
    "Education- Tertiary",
    "Electricity, Gas, Water & Waste Services",
    "Financial Services- Banks",
    "Food & Beverage Services",
    "Government Bodies & Agencies",
    "Hair and Beauty",
    "Health Care and Assistance- Hospitals",
    "Health Care and Assistance- Medical & Other",
    "Information Media and Telecommunications",
    "Internet Service Providers & Data Processing Services",
    "Library and Other Information Services",
    "Manufacturing- Clothing, Textiles & Footwear",
    "Manufacturing- Food & Drinks",
    "Manufacturing- Furniture",
    "Manufacturing- Other",
    "Manufacturing- Petroleum & Coal",
    "Manufacturing- Pulp, Paper & Paperboard",
    "Mining",
    "Other",
    "Postal Services",
    "Professional Services",
    "Real Estate Services",
    "Rental & Hiring- Property Operators/Hire Facilities",
    "Retail Trade- Food Retailing",
    "Retail Trade- Fuel Retailing",
    "Retail Trade- Motor Vehicle",
    "Retail Trade- Online Non Store Based",
    "Retail Trade- Store-Based",
    "Sports and Recreation- Gym, Sports Clubs & Other",
    "Transport Services",
    "Warehousing & Storage Services",
    "Wholesale Trade- Grocery & Other",
    "Wholesale Trade- Machinery & Equipment",
    "Wholesale Trade- Motor Vehicle & Parts",
    "Wholesale Trade- Other Goods"
];