import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { SignatureFormDetail } from 'src/app/modules/shared/models/SignatureForm';
import { UNITTYPE, STREETTYPE, AU_STATES_WITH_SHORTNAME } from 'src/environments/constants';

@Component({
  selector: 'app-signature-form',
  templateUrl: './signature-form.component.html',
  styleUrls: ['./signature-form.component.scss']
})
export class SignatureFormComponent implements OnInit {

  private _emailToken: string;
  sysMessage = 'Loading...';
  companyName = 'GB Environmental Pty Ltd';
  isAgreed = false;
  ownerSignedName = '';
  mandatoryDeclaration: string[] = [];
  assignmentDetail: SignatureFormDetail;

  private _installationAddress: string;
  get fullInstallationAddress() {
    return this._installationAddress ? this._installationAddress : this.formatInstallationAddress();
  };

  private _panelDetail: string;
  get panelDetail() {
    return this._panelDetail ? this._panelDetail : this.formatPanelDetail();
  };

  private _inverterDetail: string;
  get inverterDetail() {
    return this._inverterDetail ? this._inverterDetail : this.formatInverterDetail();
  };

  constructor(
    private _auth: AuthService,
    private _router: ActivatedRoute
  ) { }

  ngOnInit() {
    this._router.queryParamMap.subscribe(queryParams => {
      this._emailToken = queryParams.get('token');

      if (this._emailToken) {
        this._auth.getSignatureInfo({ token: this._emailToken }).subscribe(res => {
          if (res.message) {
            // load error
            this.sysMessage = res.details;
          } else {
            this.assignmentDetail = res;
            this.assignmentDetail.termsAndConditionList = this.assignmentDetail.termsAndConditions.split('- ').filter(aTerm => {
              return aTerm.length > 0;
            });
            const lastIndex = this.assignmentDetail.termsAndConditionList.length - 1;
            this.assignmentDetail.termsAndConditionList[lastIndex] = this.assignmentDetail.termsAndConditionList[lastIndex]
              .replace("www.green-bank.com.au", '<a href="www.green-bank.com.au">www.green-bank.com.au</a>');
            this.setupMandatoryDeclaration();
          }
        });
      }
    });
  }

  submitSignature() {
    this._auth.updateEmailSignature({ token: this._emailToken, ownerSignedName: this.ownerSignedName, isAgreed: this.isAgreed }).subscribe(
      res => {
        if (res) {
          this.assignmentDetail = null;
          this.sysMessage = "Signature successfully submitted.\nThank you for submitting the signature form.";
        } else {
          // load error
          this.sysMessage = 'Link Expired!'
        }
      }
    );
  }

  transformTitlecase(input: string): string {
    if (!input) {
      return '';
    } else {
      return input.replace(/\w\S*/g, (txt => txt[0].toUpperCase() + txt.substr(1).toLowerCase()));
    }
  }

  formatInstallationAddress(): string {
    var unit = '';
    var street = '';
    var suburb = '';
    var postcode = '';
    var stateAbbreviation = '';
    var unitType = '';
    this._installationAddress = ''
    if (this.assignmentDetail.installUnitType && 0 < this.assignmentDetail.installUnitType && this.assignmentDetail.installUnitType <= STREETTYPE.length) {
      unitType = UNITTYPE.filter(aUnitType => aUnitType.id == this.assignmentDetail.installUnitType)[0].name;
    }
    unit = unitType + ' ' + this.assignmentDetail.installUnitNumber;
    if (this.assignmentDetail.installStreetType && 0 < this.assignmentDetail.installStreetType && this.assignmentDetail.installStreetType <= STREETTYPE.length) {
      let streetType = STREETTYPE.filter(aStreetType => aStreetType.id == this.assignmentDetail.installStreetType)[0].name;
      street = this.assignmentDetail.installStreetNumber + ' ' + this.assignmentDetail.installStreetName + ' ' + streetType;
    }
    suburb = this.assignmentDetail.installSuburb;
    if (this.assignmentDetail.installState && this.assignmentDetail.installState > 0) {
      stateAbbreviation = AU_STATES_WITH_SHORTNAME.filter(aState => aState.id == this.assignmentDetail.installState)[0].abbreviation;
    }
    postcode = this.assignmentDetail.installPostcode;
    if (unit && unit.trim().length > 0) {
      this._installationAddress = unit + ', ';
    }
    if (street && street.trim().length > 0) {
      this._installationAddress += street + ', ';
    }
    if (suburb && suburb.trim().length > 0) {
      this._installationAddress += suburb + ', ';
    }
    if (stateAbbreviation && stateAbbreviation.trim().length > 0) {
      this._installationAddress += stateAbbreviation + ', ';
    }
    this._installationAddress = this._installationAddress += postcode;
    return this._installationAddress;
  }

  formatPanelDetail(): string {
    if (this.assignmentDetail.panels.length > 0) {
      if (this.assignmentDetail.panelsSameModel) {
        const thePanel = this.assignmentDetail.panels[0];
        this._panelDetail = thePanel.brand
          + ' ' + thePanel.model;
        if (this.assignmentDetail.panels.length > 1) {
          this._panelDetail = this.assignmentDetail.panels.length + ' * ' + this._panelDetail;
        }
        return this._panelDetail;
      } else {
        this._panelDetail = '';
        this.assignmentDetail.panels.forEach((aPanel, index) => {
          this._panelDetail += aPanel.brand
            + ' ' + aPanel.model;
          if (this.assignmentDetail.panels.length > 1 && this.assignmentDetail.panels.length != index - 1) {
            this._panelDetail = this._panelDetail + '\n';
          }
        });
        return this._panelDetail;
      }
    }
    return '';
  }

  formatInverterDetail(): string {
    if (this.assignmentDetail.inverters.length > 0) {
      if (this.assignmentDetail.invertersSameModel) {
        const theInverter = this.assignmentDetail.inverters[0];
        this._inverterDetail = theInverter.inverterBrand
          + ' ' + theInverter.inverterSeries
          + ' ' + theInverter.inverterModel;
        if (this.assignmentDetail.inverters.length > 1) {
          this._inverterDetail = this.assignmentDetail.inverters.length + ' * ' + this._inverterDetail;
        }
        return this._inverterDetail;
      } else {
        this._inverterDetail = '';
        this.assignmentDetail.inverters.forEach((aInverter, index) => {
          this._inverterDetail += aInverter.inverterBrand
            + ' ' + aInverter.inverterSeries
            + ' ' + aInverter.inverterModel;
          if (this.assignmentDetail.inverters.length > 1 && this.assignmentDetail.inverters.length != index - 1) {
            this._inverterDetail = this._inverterDetail + '\n';
          }
        });
        return this._inverterDetail;
      }
    }
    return '';
  }

  setupMandatoryDeclaration() {
    this.mandatoryDeclaration = [
      'I hereby assign the right to create Small-scale technology certificates (STCs) for the above System to ' + this.companyName + '.',
      `I understand that the System is eligible for <b>` + this.assignmentDetail.paymentRECs + `</b> STCs and,
         in exchange for assigning my right to create these STCs, ` + this.companyName + ` will make payment to the person nominated in the payment details.`,
      'I have read and understand the full terms and conditions on the website at <a href="www.green-bank.com.au/legal-notices">www.green-bank.com.au/legal-notices</a>',
      'I am aware that penalties apply for providing misleading information under the Renewable Energy (Electricity) Act 2000',
      `By signing this Assignment Form I acknowledge that the accredited installer and/or supervisor mentioned in this form, physically attented and installed/supervised
        the installation of my system.`
    ];
  }

}