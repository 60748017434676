import { Moment } from "moment";

export class AssignmentViewResponse {
  public assignments: Array<AssignmentViewItem>;
  public rows: number;
  public totalRows: number;
}

export class AssignmentViewItem {
  public link: string = '';
  public MYOBUpdatedDate: Moment;
  public MYOBUpdatedDateFormatted: string = '';
  public accountManagerPerson: string = '';
  public accreditationNumber: string = '';
  public address: string = '';
  public assignmentSerialDisplay: string = '';
  public createdBy: number = 0;
  public createdDate: Moment;
  public createdDateFormatted: string = '';
  public createdDateWithoutTime: Moment;
  public exportFileName: string = '';
  public exportStatus: number = 0;
  public guest: string = '';
  public installDate: Moment;
  public installDateFormatted: string = '';
  public installPropertyName: string = '';
  public installer: string = '';
  public installerSerial: number = 0;
  public isolators: string = '';
  public movedToCheckingByName: string = '';
  public multiplier: string = '';
  public myobStatus: number = 0;
  public myobStatusColour: string = '';
  public myobStatusName: string = '';
  public name: string = '';
  public ownerFirstName: string = '';
  public ownerLastName: string = '';
  public ownerPhoneBH: string = '';
  public ownerType: string = '';
  public paidDate: Moment;
  public paidDateFormatted: string = '';
  public paidStatus: number = 0;
  public paidStatusColour: string = '';
  public paidStatusName: string = '';
  public paymentABN: string = '';
  public paymentGST: string = '';
  public paymentName: string = '';
  public paymentPersonalUse: string = '';
  public paymentPrice: string = '';
  public paymentRECs: number = 0;
  public numberESCorVEEC: number = 0;
  public paymentReceivedDate: Moment;
  public paymentTotal: string = '';
  public paymentType: string = '';
  public powerPoints: string = '';
  public priceFix: number = 0;
  public priceFixIncGST: number = 0;
  public pricePerSTC: number = 0;
  public priceSTCIncGST: number = 0;
  public receivedDateFormatted: string = '';
  public registryStatus: number = 0;
  public registryStatusColour: string = '';
  public registryStatusName: string = '';
  public selected: number = 0;
  public serial: number = 0;
  public status: number = 0;
  public statusColour: string = '';
  public statusName: string = '';
  public submittedByName: string = '';
  public todayPrice: number = 0;
  public transitionalMultiplier: number = 0;
  public type: number = 0;
  public updatedDate: Moment;
  public isPaperClaim: boolean = false;
  constructor() {

  }
}

export class recPriceModel {
  public recPriceSerial: number;
  public date: Moment;
  public lgcNon: number;
  public lgc: number;
  public stcNon: number;
  public stc: number;
  public escNon: number;
  public esc: number;
  public veecNon: number;
  public veec: number;
  public formtrackStcNon: number;
  public formtrackStc: number;
  public dateFormatted: string;
}

export class ExportFieldsModelResponse {
  public exportFields: Array<ExportFieldsModel>;

}
export class ExportFieldsModel {
  public name: string;
  public tableName: string;
  public select: boolean;
  constructor(model?: ExportFieldsModel, name?: string, tableName?: string, select: boolean = false) {
    if (model) {
      Object.assign(this, model);
    } else {
      this.name = name;
      this.tableName = tableName;
    }
    this.select = select;
  }
}

export class FilesViewResponse {
  public files: Array<FilesViewItem>;
}

export class FilesViewItem {
  public fileSerial: number;
  public fileName: string;
  public createdDate: Date;
  public createdBy: string;
  public createdDateFormatted: string;
  public totalRECs: number;
}
