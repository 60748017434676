import { Component, OnInit, ViewChild, Output, EventEmitter, Input, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm } from '@angular/forms';
import { FileModel } from '../../modules/assignment-pvd/models/PVDAssignmentResponse';
import { ApiService } from '../../services/api.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ConfirmationDialogModel } from '../../modules/shared/models/confirmation-dialog.model';
import { ConfirmationDialogComponent } from '../../modules/shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-induction-form',
  templateUrl: './induction-form.component.html',
  styleUrls: ['./induction-form.component.scss']
})
export class InductionFormComponent implements OnInit {

  @ViewChild('userFormRef', { static: true }) systemForm: NgForm;
  dynamicArray = [];
  newDynamic;
  angForm: FormGroup;
  date;
  public essActivitiesRoles = ["Sales Representative/Site Assessor", "Qualified Installer", "Admin/Office Staff"];
  public licenseTypes = ["Plumber", "Electrician", "Other"];
  public InductionFormComponent: boolean = true;
  public partnerInductionPhotoId_Name: string = "";
  public partnerInductionConnectionAccreditationCertificate_Name: string = "";
  public qualifiedInstallerValidationRequired: boolean = false;

  constructor(private _auth: AuthService, private _router: ActivatedRoute, private fb: FormBuilder, private _api: ApiService, private matDialog: MatDialog,
    private snackBar: MatSnackBar) {
    this.createForm();
  }

  //Upload File Drag and Drop box interactive elements
  dragAreaClass: string = 'dragarea';
  @Input() maxFiles: number = 1;
  @HostListener('dragover', ['$event']) onDragOver(event) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dragend', ['$event']) onDragEnd(event) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop', ['$event']) onDrop(event) {
    this.dragAreaClass = "dragarea";
    var fileName = event.srcElement.id;
    var files = event.dataTransfer.files;
    this.validateFileUploadType(files, fileName);
    event.preventDefault();
    event.stopPropagation();
  }

  ngOnInit() {
    this.dynamicArray.push({ qualification: '', verificationID: '', expiryDate: '', copyOfLicense: '' });
    this.date = new Date().toISOString().slice(0, 10);
  }

  createForm() {
    this.angForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      email: ['', Validators.required],
      essActivityRole: ['', Validators.required],
      organizationName: ['', Validators.required],
      partnerInductionPhotoId: [],
      partnerInductionConnectionAccreditationNumber: [],
      partnerInductionConnectionAccreditationCertificate: [],
      qualifications: []
    });
  }

  addRow() {
    this.dynamicArray.push({ qualification: '', verificationID: '', expiryDate: '', copyOfLicense: '' });
  }

  //deletes a row from the dynamic array and the licenseDocumentArray 
  deleteRow(index) {
    this.dynamicArray.splice(index, 1);
  }

  getVal(value, index, type) {
    if (type === 'qualification') {
      this.dynamicArray[index].qualification = value;
    }

    if (type === 'verificationID') {
      this.dynamicArray[index].verificationID = value;
    }

    if (type === 'expiryDate') {
      this.dynamicArray[index].expiryDate = value;
    }

    if (type === 'copyOfLicense') {
      var files = value.target.files;
      if(this.validateFileUploadType(files, "")) {
        this.dynamicArray[index].copyOfLicense = value.target.files[0];
      } else {
        value.target.value = null
      }
    }

  }

  onSubmit() {
    let formInput = {};
    formInput = this.angForm.value;
    formInput['qualifications'] = this.dynamicArray;

    const confirmationDialog = new ConfirmationDialogModel('Submit ', 'Please confirm your details before submit this form', 'Submit');
    const confirmationDialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      data: confirmationDialog
    });

    confirmationDialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this._api.post<any>('flexwrapper', 'login', 'feedPartnerInduction', { formData: formInput }, false, true).subscribe(
          res => {
            let msg = 'You\'re partner induction form has successfully submitted to Greenbank Environmental. We will contact you soon regarding the induction.';
            if (!res) {
              msg = 'We have found a duplicate entry for this contact number. Please contact our support team on 03 9845 3000';
            }

            this.snackBar.open(msg, "Close", {
            });
          }
        );
      }
    });
  }

  onPhotoIdFileUpload(event) {
    let files = event.target.files;
    this.validateFileUploadType(files, "partnerInductionPhotoId");
  }
  onAccreditationFileUpload(event) {
    let files = event.target.files;
    this.validateFileUploadType(files, "partnerInductionConnectionAccreditationCertificate");
  }

  //Validate the file type
  validateFileUploadType(files, fileName) {
    //for the induction form submissions confirm that only images: png,jpg,jpeg are being uploaded
    for (var j = 0; j < files.length; j++) {
      var regexpResult = files[j].type.match(new RegExp("(\/jpg)|(\/jpeg)|(\/png)|(\/pdf)"));
      if (regexpResult == undefined || regexpResult == null || regexpResult.length < 1) {
        let confirmationDialog = new ConfirmationDialogModel("File cannot be uploaded", "Invalid File type for file upload, please only include image files (png, jpeg, jpg, etc)", '', 'Ok');
        this.matDialog.open(ConfirmationDialogComponent, {
          data: confirmationDialog
        });
        return false;
      }
      switch (fileName) {
        case "partnerInductionPhotoId":
          this.angForm.get('partnerInductionPhotoId').setValue(files[0]);
          this.partnerInductionPhotoId_Name = files[0].name;
          break;
        case "partnerInductionConnectionAccreditationCertificate":
          this.angForm.get('partnerInductionConnectionAccreditationCertificate').setValue(files[0]);
          this.partnerInductionConnectionAccreditationCertificate_Name = files[0].name;
          break;
      }
    }
    return true;
  }

  //Update the requirements of the page based on the ess activity role
  onESSActivityRoleChange(role) {
    if (role == "Qualified Installer") {
      //update PartnerInductionPhotoId and qualification requirements when ess role is Qualified installer
      this.angForm.controls['partnerInductionPhotoId'].setValidators([Validators.required]);
      this.angForm.controls['partnerInductionPhotoId'].updateValueAndValidity()
      this.qualifiedInstallerValidationRequired = true;
    } else {
      this.angForm.controls['partnerInductionPhotoId'].setValidators([]);
      this.angForm.controls['partnerInductionPhotoId'].updateValueAndValidity()
      this.qualifiedInstallerValidationRequired = false;
    }
  }

  isPhotoIdRequired() {
    return this.angForm.controls['partnerInductionPhotoId'].status == "INVALID"
  }
  isLicenseRequired() {
    //Ensure we get one completed license for the form to be valid if a license is required
    if (this.qualifiedInstallerValidationRequired &&
      ((this.dynamicArray == null || this.dynamicArray.length == 0) || 
      (this.dynamicArray[0].qualification == null || this.dynamicArray[0].qualification == '' ||
      this.dynamicArray[0].verificationID == null || this.dynamicArray[0].verificationID == '' ||
      this.dynamicArray[0].expiryDate == null || this.dynamicArray[0].expiryDate == '' ||
      this.dynamicArray[0].copyOfLicense == null || this.dynamicArray[0].copyOfLicense == ''))) {
      this.angForm.controls['qualifications'].setValidators([Validators.required]);
      this.angForm.controls['qualifications'].setErrors({ 'incorrect': true });
      this.angForm.controls['qualifications'].updateValueAndValidity()
      return false;
    }
    this.angForm.controls['qualifications'].setValidators([]);
    this.angForm.controls['qualifications'].setErrors(null);
    this.angForm.controls['qualifications'].updateValueAndValidity()
    return true;
  }

}
