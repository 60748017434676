import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Formtrack';

  constructor(private snackBar: MatSnackBar,private swUpdate: SwUpdate) {
    swUpdate.available.subscribe(event => {
      if(event.type = "UPDATE_AVAILABLE") {
        window.location.reload();
        const snack = snackBar.open('Formtrack has an update available', 'Update Now');
        snack
          .onAction()
          .subscribe(() => {
            window.location.reload();
          });
      }
    });
  }
}
