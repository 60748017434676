import { Injectable, EventEmitter } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from "moment";
import * as ENV from 'src/environments/environment';
import * as myGlobals from 'src/environments/constants';
import { AssignmentViewResponse, recPriceModel, ExportFieldsModelResponse, ExportFieldsModel, FilesViewResponse } from '../models/assignments-view';
import { ApiService } from '../../../services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { PVDAssignmentItem, PVDHistory, PVDNote, AccountManagerResponse, AccountManagerModel, SerialNumbersInfo } from '../../assignment-pvd/models/PVDAssignmentResponse';
import { ImportModel, ImportModelResponse } from '../../shared/models/import.model';
import { SWHAssignmentItem } from '../../assignment-swh/models/SWHAssignmentResponse';
import { User } from '../../shared/models/user';
import { GridFilter } from '../../shared/models/filter';
import { ReadyToSubmitModel } from '../models/readytosubmitmodel';
import { ReadytosubmitDialogComponent } from '../components/readytosubmit-dialog/readytosubmit-dialog.component';
import { EmailModel } from '../../control-centre/Models/control-centre-response';
import { FormtrackResponse } from '../../shared/models/formtrack.http';
import { ConfirmationDialogModel } from '../../shared/models/confirmation-dialog.model';
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AssignmentsService {

  // The below variables are required to store VIEW and STATUS states of the grid
  public VIEWS = myGlobals.VIEWS;
  public _view: myGlobals.VIEWS = myGlobals.VIEWS.ALL;
  public STATUS = myGlobals.STATUS;
  public _status: myGlobals.STATUS = myGlobals.STATUS.ALL;
  public gridState: any = null;
  public gridAllAssState: any = null;
  public gridReportAssState: any = null;

  public totalSubmittedRECs: number = 0;
  public loaded: boolean = false;
  public refreshAfterSave: boolean = false;
  public loadedView: myGlobals.VIEWS;
  public ownerType = myGlobals.OwnerType;
  public propertyType = myGlobals.PropertyType;
  public pendingCSV: number = 0;
  public checkingMB: number = 0;
  public pendingMYOB: number = 0;
  public recPrice: recPriceModel = new recPriceModel();
  public importCSV: ImportModel = new ImportModel();
  public exportFields: Array<ExportFieldsModel>;
  public assignmentView: PVDAssignmentItem | SWHAssignmentItem;
  public viewAssignment: number = 0;
  public goToView = new EventEmitter<number>();
  public assignmentFetched = new EventEmitter<boolean>();
  public refreshView = new EventEmitter<boolean>();
  public assignmentSubscription: Subscription;
  public loading = new EventEmitter<boolean>();
  public loadingGrid = new EventEmitter<boolean>();
  public viewTab = new EventEmitter<number>();
  public isStaff: boolean = false;
  public searchInput: string = "";
  public searchSerialInput: string = "";
  public accountManagerSource: Array<AccountManagerModel>;

  public types: any[] = [
    { value: myGlobals.ASSIGNMENT_TYPE_PVD, label: myGlobals.PVD_NAME },
    { value: myGlobals.ASSIGNMENT_TYPE_SWH, label: myGlobals.SWH_NAME }
  ];
  private typesAdapter = new jqx.dataAdapter(this.types, { autoBind: true });
  // private typesSource: any =
  //   {
  //     datatype: 'array',
  //     datafields: [
  //       { name: 'label', type: 'string' },
  //       { name: 'value', type: 'string' }
  //     ],
  //     localdata: this.types
  //   }
  public ownerTypes: any[] = myGlobals.OWNERTYPE.filter(ownerType => ownerType.id != 0)
    .map(ownerType => {
      return { value: ownerType.id, label: ownerType.name }
    });
  private ownerTypeAdapter = new jqx.dataAdapter(this.ownerTypes, { autoBind: true });

  public paymentStatus: any[] = myGlobals.PAID_STATUS.map(status => { return { value: status.id, label: status.name } });
  private paymentStatusAdapter = new jqx.dataAdapter(this.paymentStatus, { autoBind: true });

  public registryStatus: any[] = myGlobals.REGISTRY_STATUS.filter(status => {
    return [0, 2, 4, 6].includes(status.id);
  }).map(status => {
    return { value: status.id, label: status.name }
  });
  private registryStatusAdapter = new jqx.dataAdapter(this.registryStatus, { autoBind: true });

  public myobStatus: any[] = myGlobals.MYOB_STATUS.map(status => { return { value: status.id, label: status.name } });
  private myobStatusAdapter = new jqx.dataAdapter(this.myobStatus, { autoBind: true });

  public assignmentStatus: any[] = myGlobals.ASSIGNMENTS_STATUS.filter(status => {
    return status.id;
  }).map(status => {
    return { value: status.name, label: status.name }
  });
  public clientAssignmentStatus: any[] = myGlobals.ASSIGNMENTS_STATUS.filter(status => {
    return [0, 12, 11, 13, 14, 15, 15].includes(status.id);
  }).map(status => {
    return { value: status.name, label: status.name }
  });
  private assignmentStatusAdapter = new jqx.dataAdapter(this.assignmentStatus, { autoBind: true });
  public source: any =
    {
      localdata: [],
      totalrecords: 100,
      complete: 0,
      checking: 0,
      pending: 0,
      all: 0,
      incomplete: 0,
      datafields:
        [
          { name: 'link', type: 'string' },
          { name: 'serial', type: 'string' },
          { name: 'createdDate', type: 'date', cellsformat: myGlobals.DATE_FORMAT_FILTER },
          { name: 'assignmentName', type: 'string', value: 'statusName', values: { source: this.assignmentStatusAdapter.records, value: 'value', name: 'label' } },
          { name: 'statusName', type: 'string' },
          { name: 'typeName', type: 'string', value: 'type', values: { source: this.typesAdapter.records, value: 'value', name: 'label' } },
          { name: 'type', type: 'string' },
          { name: 'paymentReceivedDate', type: 'date', cellsformat: myGlobals.DATE_FORMAT_FILTER },
          { name: 'ownerType', type: 'string', value: 'ownerType', values: { source: this.ownerTypeAdapter.records, value: 'value', name: 'label' } },
          { name: 'name', type: 'string' },
          { name: 'address', type: 'string' },
          { name: 'pricePerSTC', type: 'number' },
          { name: 'todayPrice', type: 'string' },
          { name: 'paymentGST', type: 'string' },
          { name: 'paymentTotal', type: 'double' },
          { name: 'paymentPrice', type: 'double' },
          { name: 'paymentRECs', type: 'number' },
          { name: 'escS', type: 'double' },
          { name: 'escPrice', type: 'double' },
          { name: 'veecS', type: 'double' },
          { name: 'veecPrice', type: 'double' },
          { name: 'paymentStatusName', type: 'string', value: 'paymentName', values: { source: this.paymentStatusAdapter.records, value: 'value', name: 'label' } },
          { name: 'paymentName', type: 'string' },
          { name: 'paidStatusName', type: 'string' },
          { name: 'paidStatusColour', type: 'string' },
          { name: 'paidDate', type: 'date', cellsformat: myGlobals.DATE_FORMAT_FILTER },
          { name: 'accreditationNumber', type: 'string' },
          { name: 'registryStatusName', type: 'string', value: 'registryStatus', values: { source: this.registryStatusAdapter.records, value: 'value', name: 'label' } },
          { name: 'registryStatus', type: 'string' },
          { name: 'installer', type: 'string' },
          { name: 'installDate', type: 'date', cellsformat: myGlobals.DATE_FORMAT_FILTER },
          { name: 'assignmentSerialDisplay', type: 'string' },
          { name: 'exportFileName', type: 'string' },
          { name: 'accountManagerPerson', type: 'string' },
          { name: 'MYOBUpdatedDate', type: 'date', cellsformat: myGlobals.DATE_FORMAT_FILTER },
          { name: 'myobStatus', type: 'string', value: 'myobStatus', values: { source: this.myobStatusAdapter.records, value: 'value', name: 'label' } },
          { name: 'myobStatusName', type: 'string' },
          { name: 'myobStatusColour', type: 'string' },
          { name: 'submittedByName', type: 'string' },
          { name: 'movedToCheckingByName', type: 'string' },
          { name: 'statusColour', type: 'string' },
          { name: 'registryStatusColour', type: 'string' },
          { name: 'fileName', type: 'string' },
          { name: 'totalRECs', type: 'string' },
          { name: 'fileSerial', type: 'number' },
          { name: 'createdDateFormatted', type: 'string' },
          { name: 'exportStatus', type: 'number' },
          { name: 'existClientCard', type: 'string' },
          { name: 'createdBy', type: 'string' },
          { name: 'isPaperClaim', type: 'boolean' },
        ],
      datatype: 'json'
    }

  public filters: GridFilter = new GridFilter('');
  public allAssignmentFilters: GridFilter = new GridFilter('');
  public reportFilters: GridFilter = new GridFilter('');
  public previousFilter = [];
  public dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });

  constructor(
    private _api: ApiService,
    private _auth: AuthService,
    public sb: MatSnackBar,
    public matDialog: MatDialog
  ) { }

  getAssignmentView<T>(serial: string, swh?: boolean) {
    this.loading.emit(true);
    const func = swh ? 'getSwhAssignment' : 'getPvdAssignment';
    this._api.get<T>('greenbank', 'assignment', func, { arguments: serial }).subscribe(
      res => {
        if (res != null) {
          if (swh) {
            this.assignmentView = new SWHAssignmentItem().deserialize(res);
            this.checkSwhSerialDuplicates();
          }
          else {
            this.assignmentView = new PVDAssignmentItem().deserialize(res);
            this.calcSerialNumbers();
          }

          this.assignmentFetched.emit(true);
          this.loading.emit(false);
        }
      }
    );
  }

  calcSerialNumbers() {
    (this.assignmentView as PVDAssignmentItem).serialNumbersInfo = new Array<SerialNumbersInfo>();
    if ((this.assignmentView as PVDAssignmentItem).serialNumbers.length > 0) {
      (this.assignmentView as PVDAssignmentItem).serialNumbers.forEach(element => {

        let checkPvdSerialfilters = {
          checkPVDSerial: element[0],
          checkPVDAssigmentSerial: this.assignmentView.assignmentSerial
        };
        this.checkPvdSerialDuplicates(checkPvdSerialfilters).subscribe(
          res => {
            let item: SerialNumbersInfo = new SerialNumbersInfo();
            item.serialNumber = element[0];
            item.duplicate = false;
            item.verified = element[1] === '0' ? false : true;
            if (res && res.length > 0) {
              item.duplicate = true;
            }
            (this.assignmentView as PVDAssignmentItem).serialNumbersInfo.push(item);
          }
        );
      });
    }
  }

  checkPvdSerialDuplicates(checkPvdSerialfilters): Observable<any> {
    return this._api.get<any>('greenbank', 'assignment', 'checkPvdSerialDuplicates', checkPvdSerialfilters, 'arguments');
  }

  checkSwhSerialDuplicates() {
    let postParams = {
      AssignmentSerial: this.assignmentView.assignmentSerial,
      Serial: (this.assignmentView as SWHAssignmentItem).serialNumber
    };
    this._api.get<SWHAssignmentItem>('greenbank', 'assignment', 'checkSwhSerial', postParams).subscribe(
      res => {
        (this.assignmentView as SWHAssignmentItem).isDuplicateTankSerial = false;
        if (res != null) {
          (this.assignmentView as SWHAssignmentItem).isDuplicateTankSerial = true;
        }
      });
  }

  deleteAssignment(params: any, functionName: string) {
    return this._api.get<PVDNote>('greenbank', 'assignment', functionName, params, 'arguments');
  }

  cancelAssignment(params: any, functionName: string) {
    return this._api.get<PVDNote>('greenbank', 'assignment', functionName, params, 'arguments');
  }

  getPersonBySerial(params: any) {
    return this._api.get<User>('contacts', 'person', 'getPersonBySerial', params, 'arguments');
  }

  getPvdHistoryList(params: any, isPvd: boolean): Observable<Array<PVDHistory>> {
    var functionName = 'getSwhHistoryList';
    if (isPvd) {
      functionName = 'getPvdHistoryList'
    }
    return this._api.get<Array<PVDHistory>>('greenbank', 'history', functionName, params, 'arguments');
  }

  getNotes(params: any): Observable<Array<PVDNote>> {
    return this._api.get<Array<PVDNote>>('greenbank', 'note', 'getNotes', params, 'arguments');
  }

  saveNote(params: any) {
    return this._api.get<PVDNote>('greenbank', 'note', 'saveNote', params, 'arguments');
  }

  deleteNote(params: any) {
    return this._api.get<PVDNote>('greenbank', 'note', 'deleteNoteBySerial', params, 'arguments');
  }

  getPagedAssignments(filters) {
    this.loadingGrid.emit(true);
    filters.take = filters.pageSize;
    filters.skip = (filters.page - 1) * filters.pageSize;
    return this._api.get<AssignmentViewResponse>('greenbank', 'assignment', 'getPagedAssignments', filters, 'filters').pipe(
      map(res => {
        if (res != null && res.assignments != null) {
          res.assignments.map(
            i => {
              let type = i.type == 0 ? 'swh' : 'pvd';
              i.link = type + "/" + i.serial.toString();
              if (i.paymentReceivedDate == '0000-00-00')
                i.paymentReceivedDate = '';
              if (i.paidDate == '0000-00-00')
                i.paidDate = '';
              if (i.installDate == '0000-00-00')
                i.installDate = '';
              if (i.MYOBUpdatedDate == '0000-00-00')
                i.MYOBUpdatedDate = '';
              i.address = i.address && i.address !== '' ? i.address.replace(/\"/g, "") : '';

              let assStatus = myGlobals.ASSIGNMENTS_STATUS.find(status => status.id == i.status);
              i.statusName = assStatus ? assStatus.name : '';
              i.statusColour = assStatus ? assStatus.colour : '';

              let paidStatus = myGlobals.PAID_STATUS.find(status => status.id == i.paidStatus);
              i.paidStatusName = paidStatus ? paidStatus.name : '';
              i.paidStatusColour = paidStatus ? paidStatus.colour : '';

              let registryStatus = myGlobals.ASSIGNMENTS_STATUS.find(status => status.id == i.registryStatus);
              i.registryStatusName = registryStatus ? registryStatus.name : '';
              i.registryStatusColour = registryStatus ? registryStatus.colour : '';

              let myobStatus = myGlobals.MYOB_STATUS.find(status => status.id == i.myobStatus);
              i.myobStatusName = myobStatus ? myobStatus.name : '';
              i.myobStatusColour = myobStatus ? myobStatus.colour : '';
            });

        } else {
          this.loadingGrid.emit(false);
        }
        this.source.localdata = res.assignments;
        this.source.totalrecords = res.totalRows;
        this.dataAdapter.dataBind();
        this.loadingGrid.emit(false);
      })
    )
  }

  getPagedAssignmentTotals(filters) {
    return this._api.get<Object>('greenbank', 'assignment', 'getPagedAssignmentTotals', filters, 'filters').pipe(
      map(res => {
        if (res != null) {
          Object.assign(this.source, res);
        }
      })
    )
  }

  getFiles(filters) {
    this.loadingGrid.emit(true);
    return this._api.get<FilesViewResponse>('greenbank', 'file', 'getPagedFileList', filters, 'filters').pipe(
      map(res => {
        if (res != null) {
          this.source.localdata = res.data;
          this.source.totalrecords = res.data.length;
          this.dataAdapter.dataBind()
          this.loadingGrid.emit(false);
        }
      })
    )
  }

  getTodayRecPrice() {
    this._api.get<recPriceModel>('greenbank', 'setting', 'getTodayRecPrice', null).subscribe(
      res => {
        if (res != null) {
          this.recPrice = res;
        }
      }
    )
  }

  getTotalCompanySubmittedRECs(params?: any) {
    var postParams = {};
    if (!params) {
      postParams = { groupSerial: this._auth.company.groupSerial, dealStartDate: this._auth.company.dealStartDate, dealEndDate: this._auth.company.dealEndDate };
    } else {
      postParams = params;
    }
    this._api.get<recPriceModel>('greenbank', 'assignment', 'getTotalCompanySubmittedRECs', postParams).subscribe(
      res => {
        if (res != null) {
          this.totalSubmittedRECs = res;
        }
      }
    );
  }

  getImportRec() {
    let params = { componentName: 'importexport', sectionSerialCSV: 5, profileTypeCSV: 1, asArrayCSV: 1, };
    this._api.get<ImportModelResponse>('greenbank', 'import', 'getProfilesBySectionSerialAndProfileType', params, 'arguments').subscribe(
      res => {
        if (res != null) {
          this.importCSV = res;
        }
      }
    )
  }

  getExportFields(assignmentType: number) {
    return this._api.get<ExportFieldsModelResponse>('greenbank', 'assignment', 'getExportFieldsAssignments', { assingType: assignmentType }, 'arguments');
  }

  getNumberChecking(filters) {
    this._api.get<any>('greenbank', 'assignment', 'getPagedAssignmentTotals', filters, 'filters').subscribe(
      res => {
        if (res) {
          this.checkingMB = res.checking;
        }
      }
    )
  }

  getNumberCSV(filters) {
    this._api.get<any>('greenbank', 'assignment', 'getPagedAssignmentTotals', filters, 'filters').subscribe(
      res => {
        if (res) {
          this.pendingCSV = res.all;
        }
      }
    )
  }

  getNumberMYOB(filters) {
    this._api.get<AssignmentViewResponse>('greenbank', 'assignment', 'getPagedAssignments', filters, 'filters')
      .subscribe(res => {
        if (res) {
          this.pendingMYOB = res.assignments.length;
        }
      }
      )
  }

  printAssignment(filters, type) {
    let method: string = type == 'swh' ? 'printSWHAssignment' : 'printPVDAssignment';
    this._api.get<string>('greenbank', 'assignment', method, filters, 'arguments').subscribe(
      res => {
        if (res != null) {
          const session = sessionStorage.getItem('sessionId') || localStorage.getItem('sessionId');
          const externalUrl = ENV.FORMTRACK_FILE_URL_STREAM + res.toString() + '?PHPSESSID=' + session;
          window.open(externalUrl, '_blank');
        }
      }
    )
  }

  exportAssignmentCsv(filters) {
    this._api.get<string>('greenbank', 'assignment', 'exportAssignmentCsv', filters, 'arguments').subscribe(
      res => {
        if (res != null) {
          const session = sessionStorage.getItem('sessionId') || localStorage.getItem('sessionId');
          window.open(ENV.FORMTRACK_FILE_URL_STREAM + res.toString() + '?PHPSESSID=' + session)
        }
      }
    )
  }

  verifyAssignment(filters, method) {
    return this._api.get<string>('greenbank', 'assignment', method, filters, 'arguments').pipe(
      map(res => {
        if (res != null) {
          const session = sessionStorage.getItem('sessionId') || localStorage.getItem('sessionId');
          window.open(ENV.FORMTRACK_FILE_URL_DOWNLOAD + res.toString() + '?PHPSESSID=' + session)
        }
      }
      )
    );
  }

  //api call to create a csv file, the serial is then returned. 
  //The method named must correspond with its function name in the backend. 
  exportToCSV(param, method) {
    this._api.get<string>('greenbank', 'assignment', method, param, 'arguments').subscribe(
      res => {
        if (res != null) {
          const session = sessionStorage.getItem('sessionId') || localStorage.getItem('sessionId');
          window.open(ENV.FORMTRACK_FILE_URL_DOWNLOAD + res.toString() + '?PHPSESSID=' + session)
        }
      }, err => {
        console.error("ASSIGNMENTSERVICE - An Error has ocurred in exportToCSV() calling: " + method + " Resulting error: " + err);
      }
    )
  }

  getCheckingAssignmentsPrintFile(filters) {
    this._api.get<string>('greenbank', 'assignment', 'getCheckingAssignmentsPrintFile', filters, 'arguments').subscribe(
      res => {
        if (res != null) {
          const session = sessionStorage.getItem('sessionId') || localStorage.getItem('sessionId');
          window.open(ENV.FORMTRACK_FILE_URL_DOWNLOAD + res.toString() + '?PHPSESSID=' + session)
        }
      }
    )
  }

  exportMyobClientsCsv(filters, itemSelected) {
    this._api.get<string>('greenbank', 'assignment', 'exportMyobClientsCsv', filters, 'arguments').subscribe(
      res => {
        if (res != null) {
          if(res !== Number) {
            //TODO: The error returned is just a string from the backend this should be a catch instead of this garbage I wrote - LD. 
            console.log(res);
            throw res;
          }
          const session = sessionStorage.getItem('sessionId') || localStorage.getItem('sessionId');
          window.open(ENV.FORMTRACK_FILE_URL_DOWNLOAD + res.toString() + '?PHPSESSID=' + session)
          itemSelected.forEach(element => {
            this.dataAdapter['records'][element].existClientCard = true;
          });
        }
      }, err => {
        console.error("ASSIGNMENTSERVICE - An Error has ocurred in exportMyobClientsCsv(): " + err);
      }
    )
  }

  exportMyobPurchaseOrders(filters) {
    this._api.get<string>('greenbank', 'assignment', 'exportMyobOrdersCsv', filters, 'arguments').subscribe(
      res => {
        if (res != null) {
          const session = sessionStorage.getItem('sessionId') || localStorage.getItem('sessionId');
          window.open(ENV.FORMTRACK_FILE_URL_DOWNLOAD + res.toString() + '?PHPSESSID=' + session)
        }
      }
    )
  }

  getAccountManager() {
    this._api.get<AccountManagerResponse>('greenbank', 'setting', 'getAllStaffForAutoComplete', null).subscribe(
      res => {
        if (res != null) {
          this.accountManagerSource = res;
        }
      }
    )
  }

  getPVDAssignment(id: number): Observable<PVDAssignmentItem> {
    return this._api.get<PVDAssignmentItem>('greenbank', 'assignment', 'getPvdAssignment', { id: id });
  }

  getSWHAssignment(id: number): Observable<PVDAssignmentItem> {
    return this._api.get<SWHAssignmentItem>('greenbank', 'assignment', 'getSwhAssignment', { id: id });
  }

  getEmailDetail(params: any): Observable<EmailModel> {
    return this._api.get<EmailModel>('greenbank', 'assignment', 'getEmailSignatureTemplate', params, 'arguments');
  }

  requestForInformation(params: any): Observable<FormtrackResponse> {
    return this._api.get<FormtrackResponse>('greenbank', 'assignment', 'requestForInformation', params, 'arguments');
  }

  checkDigitalSignatureRequested(params: any): Observable<FormtrackResponse> {
    return this._api.get<FormtrackResponse>('greenbank', 'assignment', 'checkDigitalSignatureRequested', params, 'arguments');
  }

  submitAssignment(filters, type) {
    let method: string = type == 'swh' ? 'submitSwhAssignment' : 'submitPvdAssignment';
    console.log('filters:', filters);
    this._api.get<string>('greenbank', 'assignment', method, filters, 'arguments').subscribe(
      res => {
        if (res != null && res['Error']) {
          const dialogTitle = 'Already Submitted';
          let infoDialog = new ConfirmationDialogModel(dialogTitle, res['Error'], '', 'Ok');
          this.matDialog.open(ConfirmationDialogComponent, {
            data: infoDialog
          });
        }
      }
    )
  }

  readyToSubmit(assignmentSerial: number, type: number) {
    if (type == myGlobals.ASSIGNMENT_TYPE_PVD) {

      this.getPVDAssignment(assignmentSerial).subscribe(
        res => {
          let assignment: any = res;
          let readyToSubmitModel: ReadyToSubmitModel = this.calReadyToSubmit(assignment);
          this.openReadyToSubmitDialog(readyToSubmitModel);
        }
      );
    }
    else {
      this.getSWHAssignment(assignmentSerial).subscribe(
        res => {

          let assignment: any = res;
          let readyToSubmitModel: ReadyToSubmitModel = this.calReadyToSubmit(assignment);
          this.openReadyToSubmitDialog(readyToSubmitModel);

        }
      );
    }
  }

  openReadyToSubmitDialog(readyToSubmitModel: ReadyToSubmitModel) {
    const dialogRef = this.matDialog.open(ReadytosubmitDialogComponent, {
      data: readyToSubmitModel
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.submitAssignment({ assignmentSerialSubmit: readyToSubmitModel.assignmentSerial, dealRatePrice: readyToSubmitModel.paymentPrice }, readyToSubmitModel.assignmentType == myGlobals.ASSIGNMENT_TYPE_PVD ? 'pvd' : 'swh');
        this.refreshView.emit(true);
      }
    });
  }

  calReadyToSubmit(assignment: any): ReadyToSubmitModel {
    let owner: string = '';
    if (assignment.ownerType == myGlobals.OWNERTYPE_INDIVIDUAL_ID)
      owner = assignment.ownerFirstName + ' ' + assignment.ownerLastName;
    else
      owner = assignment.ownerOrganisationName;
    let installerAddress = this.getInstallerAddress(assignment);
    let serialNumbers = assignment.serialNumbers;
    let pricePerSTC = assignment.paymentPrice > 0 ? '$' + assignment.paymentPrice : 'TBD';

    var dealPrice = 0.00;
    // Deal Rate implementation
    if (this._auth.company.dealRateCustomer == 1 && this.isDealValid()) {
      if (this._auth.company.volumeType == 1 || (assignment.paymentRECs >= this._auth.company.volumeMinimum && this.totalSubmittedRECs <= this._auth.company.volumeMaximum)) {
        if (this._auth.company.dealType == 1) {
          dealPrice = Number(this._auth.company.dealTypeValue);
        } else {
          dealPrice = Number(this._auth.company.dealTypeValue) + Number(this.recPrice.formtrackStcNon);
        }
        pricePerSTC = '$' + dealPrice.toFixed(2);
        assignment.paymentPrice = dealPrice.toFixed(2);
      }
    }

    let gst;
    let total;
    let totalWithoutGST = assignment.paymentPrice * assignment.paymentRECs;
    if (assignment.paymentPersonalUse == 0) {
      gst = '$' + (totalWithoutGST * 0.1).toFixed(2);
      let totalWithGST = (totalWithoutGST * 1.1).toFixed(2);
      total = '$' + totalWithoutGST + ' (' + totalWithGST + ' inc. GST)';
    }
    else {
      gst = 'N/A';
      total = '$' + totalWithoutGST;
    }
    return new ReadyToSubmitModel(owner, installerAddress, serialNumbers, assignment.paymentRECs, Number(assignment.paymentPrice),
      pricePerSTC, gst, total, assignment.type, assignment.assignmentSerial, this.totalSubmittedRECs);
  }

  getInstallerAddress(assignment: any) {
    let propertyName: string = '';
    let unitNumber: string = '';
    let unitType: string = '';
    let streetNumber: string = '';
    let streetName: string = '';
    let streetType: string = '';
    let completeAddress: string = '';
    let address: string = '';
    if (assignment.installSameAsPostal) {
      if (assignment.ownerUnitType > -1) {
        unitType = myGlobals.UNITTYPE.find(x => x.id == assignment.ownerUnitType) ? myGlobals.UNITTYPE.find(x => x.id == assignment.ownerUnitType).name : '';
      }
      unitNumber = assignment.ownerUnitNumber;
      streetNumber = assignment.ownerStreetNumber;
      streetName = assignment.ownerStreetName;
      if (assignment.ownerStreetType > -1) {
        streetType = myGlobals.STREETTYPE.find(x => x.id == assignment.ownerStreetType) ? myGlobals.STREETTYPE.find(x => x.id == assignment.ownerStreetType).name : '';
      }
      completeAddress = assignment.ownerSuburb + ', ' + assignment.ownerStateName + ', ' + assignment.ownerPostcode;
    }
    else {
      propertyName = assignment.installPropertyName;
      if (assignment.ownerUnitType > -1) {
        unitType = myGlobals.UNITTYPE.find(x => x.id == assignment.installUnitType) ? myGlobals.UNITTYPE.find(x => x.id == assignment.installUnitType).name : '';
      }
      unitNumber = assignment.installUnitNumber;
      streetNumber = assignment.installStreetNumber;
      streetName = assignment.installStreetName;
      if (assignment.installStreetType > -1) {
        streetType = myGlobals.STREETTYPE.find(x => x.id == assignment.installStreetType) ? myGlobals.STREETTYPE.find(x => x.id == assignment.installStreetType).name : '';
      }
      completeAddress = assignment.installSuburb + ', ' + assignment.installStateName + ', ' + assignment.installPostcode;
    }

    address += propertyName ? propertyName + ', ' : '';
    address += unitType ? unitType + ' ' + unitNumber + ', ' : '';
    address += streetNumber + ' ' + streetName + ' ' + streetType + ', ' + completeAddress;
    return address;
  }

  isDealValid(): boolean {
    if (moment(this._auth.company.dealEndDate).isValid()) {
      return moment(this._auth.company.dealEndDate).isSameOrAfter(moment());
    }
    return false
  }

}

class GetType<T> {
  private TName: string;
  constructor(x: new () => T) {
    this.TName = x.name;
  }
}
