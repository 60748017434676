import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AssignmentsService } from '../../services/assignments.service';
import { MatDialog } from '@angular/material';
import { SWHAssignmentItem } from 'src/app/modules/assignment-swh/models/SWHAssignmentResponse';
import { PVDAssignmentItem } from 'src/app/modules/assignment-pvd/models/PVDAssignmentResponse';

@Component({
  selector: 'app-buttons',
  template: `<i class="material-icons" (click)='edit()'>edit</i>
  <i class="material-icons" (click)="getPDF()">picture_as_pdf</i>
  <i class="material-icons" (click)="view()">pageview</i>`,
  styleUrls: ['./buttons.component.scss']
})
export class ButtonsComponent {

  constructor(
    private router: Router,
    private _as: AssignmentsService,
    public dialog: MatDialog
  ) { }

  public link: string;
  public route: ActivatedRoute;

  edit() {
    this.router.navigate([this.link], { relativeTo: this.route });
  }

  getPDF() {
    let serial = this.link.split("/", 2);
    this._as.printAssignment({ serial: serial[1], guest: 0 }, serial[0]);
  }

  view() {
    this._as.goToView.emit(1);
    this._as.loading.emit(true);
    let serial = this.link.split("/", 2);
    if (serial[0] === 'swh')
      this._as.getAssignmentView<SWHAssignmentItem>(serial[1], true);
    else if (serial[0] === 'pvd')
      this._as.getAssignmentView<PVDAssignmentItem>(serial[1], false);
  }
}