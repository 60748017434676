import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReadyToSubmitModel } from '../../models/readytosubmitmodel';

@Component({
  selector: 'app-readytosubmit-dialog',
  templateUrl: './readytosubmit-dialog.component.html',
  styleUrls: ['./readytosubmit-dialog.component.scss']
})
export class ReadytosubmitDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ReadytosubmitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public summary: ReadyToSubmitModel
  ) { }

  closeDialog() {
    this.dialogRef.close();
  }
}
