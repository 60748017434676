import { FormtrackResponse, FormtrackError } from '../../shared/models/formtrack.http';
import { Moment } from 'moment';
import { OwnerType, ASSIGNMENT_TYPE_PVD, OWNERTYPE_INDIVIDUAL_ID, STREETTYPE, UNITTYPE, AU_STATES_WITH_SHORTNAME } from 'src/environments/constants';
import { User } from '../../shared/models/user';
import { Deserializable } from '../../shared/models/deserializable.model';
import { InstallActivitiesModel } from '../../assignment-swh/models/SWHAssignmentResponse';
import { FieldMappingsModel } from '../../shared/models/import.model';

export class AddressModelResponse extends FormtrackResponse {
  public data: Array<AddressModel>;
  constructor(error: FormtrackError, addressModel: { data: Array<AddressModel> }) {
    super(error, addressModel);
  }
}

export class InstallerModelResponse extends FormtrackResponse {
  public data: Array<InstallerModel>;
  constructor(error: FormtrackError, installerModel: Array<InstallerModel>) {
    super(error, installerModel);
  }
}
export class ElectricianModelResponse extends FormtrackResponse {
  public data: Array<ElectricianModel>;
  constructor(error: FormtrackError, elctricianModel: Array<ElectricianModel>) {
    super(error, elctricianModel);
  }
}

export class AccountManagerResponse extends FormtrackResponse {
  public result: Array<AccountManagerModel>;
  constructor(error: FormtrackError, accountManagerModel: Array<AccountManagerModel>) {
    super(error, accountManagerModel);
  }
}

export class SettingsResponse extends FormtrackResponse {
  public result: SettingsModel;
  constructor(error: FormtrackError, settingsModel: SettingsModel) {
    super(error, settingsModel);
  }
}

export class PVDAssignmentItem implements Deserializable {
  public installType: number = -1;
  public ownerType: OwnerType = 0;
  public guest: string;
  public assignmentSerial: number;
  public exportFileName: string;
  public personSerial = 0;
  public linkToSerial = 0;
  public linkFromSerial = 0;
  public createdDate: Date;
  public createdBy = 0;
  public updatedDate: Date;
  public updatedBy = 0;
  public submittedBy: number;
  public status = 0;
  public movedToCheckingBy: string;
  public deleted: number;
  public myobStatus: number;
  public registryStatus: number;
  public exportStatus: number;
  public paidStatus: number;
  public mobileDataSubmittedInstaller: number;
  public mobileDataSubmittedDesigner: number;
  public installOnBuilding = -1;
  public duplicateSerialOverride = 0;
  public paidDate: Date;
  public accreditationNumber: string;
  public purchaseSerial = 0;
  public purchaseFileName = '';
  public proofAddressSerial = 0;
  public panelPhotoSerial = 0;
  public proofAddressFileName = '';
  public additionalDocumentsSerial = 0;
  public additionalDocumentsFileName = '';
  public complianceSerial = 0;
  public complianceFileName = '';
  public gstInvoiceSerial = 0;
  public gstInvoiceFileName = '';
  public tax1InvoiceSerial = 0;
  public tax1InvoiceFileName = '';
  public tax2InvoiceSerial = 0;
  public tax2InvoiceFileName = '';
  public recFormSerial = 0;
  public recFormFileName = '';

  public invoiceFormSerial: number = 0;
  public invoiceFormFileName: string = '';
  public nominationFormSignedSerial: number = 0;
  public nominationFormSignedFileName: string = '';
  public originalUnitBeforeDecommissioningPhotoSerial: number = 0;
  public originalUnitBeforeDecommissioningPhotoFileName: string = '';
  public originalUnitsTankLabelPhotoSerial: number = 0;
  public originalUnitsTankLabelPhotoFileName: string = '';
  public originalUnitOnceRemovedDecommissionedPhotoSerial: number = 0;
  public originalUnitOnceRemovedDecommissionedPhotoFileName: string = '';
  public newUnitInstalledPhotoSerial: number = 0;
  public newUnitInstalledPhotoFileName: string = '';
  public newUnitsTankLabelSerial: number = 0;
  public newUnitsTankLabelFileName: string = '';
  public postImplementationFormSignedSerial: number = 0;
  public postImplementationFormSignedFileName: string = '';
  public eftposBankReceiptSerial: number = 0;
  public eftposBankReceiptFileName: string = '';
  public signatureSelfiePhotoSerial: number = 0;
  public signatureSelfiePhotoFileName: string = '';
  public signatureSelfieMiddleSerial: number = 0;
  public signatureSelfieMiddleFileName: string = '';
  public signatureSelfieEndSerial: number = 0;
  public signatureSelfieEndFileName: string = '';
  public electricityBillSerial: number = 0;
  public electricityBillFileName: string = '';
  public stcFormSerial: number = 0;
  public stcFormFileName: string = '';
  public escFormSerial: number = 0;
  public escFormFileName: string = '';
  public veecFormSerial: number = 0;
  public veecFormFileName: string = '';
  public tankPanelSerialNumberFileName: string = '';
  public tankPanelSerialNumberSerial: number = 0;
  public electricalCertificateCompliancePhotoSerial: number = 0;
  public electricalCertificateCompliancePhotoFileName:  string = '';

  public transitionalMultiplierContractSerial: number;
  public transitionalMultiplierStatDecSerial: number;
  public photoSerial = 0;
  public housePhotoSerial = 0;
  public photoGPS: string;
  public housePhotoGPS: string;
  public purchaseGPS: string;
  public complianceGPS: string;
  public photoDateTime: Date;
  public housePhotoDateTime: Date;
  public purchaseDateTime: Date;
  public panelPhotoDateTime: Date;
  public complianceDateTime: Date;
  public ownerOrganisationName = '';
  public ownerFirstName = '';
  public ownerLastName = '';
  public ownerUnitType = -1;
  public ownerUnitNumber = '';
  public ownerStreetNumber = '';
  public ownerStreetName = '';
  public ownerStreetType = 0;
  public ownerSuburb = '';
  public ownerPostcode = '';
  public ownerState = 0;
  public ownerStateName = '';
  public ownerPhoneBH = '';
  public ownerPhoneAH = '';
  public ownerEmail = '';
  public ownerLocation = '';
  public installSameAsPostal = 0;
  public installPropertyName = '';
  public installPropertyType = 0;
  public installStories = 0;
  public installUnitType = -1;
  public installUnitNumber = '';
  public installStreetNumber = '';
  public installStreetName = '';
  public installStreetType = 0;
  public installSuburb = '';
  public installPostcode = '';
  public installState = 0;
  public installGisLocation: string;
  public installLatitude = '';
  public installLongitude = '';
  public installMultiple = 0;
  public installAdditionalInformation = '';
  public installPreviousFailed = 0;
  public installPreviousFailedNotes = '';
  public installLocation = '';
  public specialAddress = '';
  public newPanelsLocation: string;
  public upgradeDetails: string;
  public installUpgrade: boolean;
  public installUpgradeDistance: string;
  public installUpgradeDirection: string;
  public brand = '';
  public model = '';
  public brand2 = '';
  public model2 = '';
  public inverterBrand = '';
  public inverterSeries = '';
  public inverterModel = '';
  public respSupp = '';
  public inverterPhotoSerial = 0;
  public inverterPhotoGPS: string;
  public inverterPhotoDateTime: Date;
  public inverterBrand2 = '';
  public inverterSeries2 = '';
  public inverterModel2 = '';
  public inverterPhoto2Serial: number;
  public inverterPhoto2GPS: string;
  public panelPhotoGPS: string;
  public inverterPhoto2DateTime: Date;
  public inverterSerialNumber = 0;
  public numberPanels = 1;
  public numberPanels2: number;
  public numberDifferentPanels: number;
  public panelsSameModel: number;
  public numberInverters = 1;
  public invertersSameModel = 1;
  public multiInvertersDetail: string;
  public capacity = 0;
  public inverterCapacity = 0;
  public retailerName = '';
  public retailerABN = '';
  public installationType = 0;
  public installationTypeInformation = '';
  public signedDataPackageFileName = '';
  public installDate: Moment;
  public wattsPerPanel = 0;
  public wattsPerPanel2 = 0;
  public receivedAssistance: number;
  public receivedCredits: number;
  public eligible: number;
  public completeUnit = -1;
  public additionalCapacityDetails = '';
  public transitionalMultiplier: number;
  public connected = 0;
  public expenses: number;
  public totalInstallCosts: number;
  public meterConnectionCosts: number;
  public otherCosts: number;
  public installationRebates: number;
  public cecStatement: number;
  public approvalStatement: number;
  public safetyStatement: number;
  public electricalStatement: number;
  public liabilityStatement: number;
  public conductStatement: number;
  public standardsStatement = 0;
  public installerSerial = 0;
  public installerClassification = -1;
  public installerDetail = new User();
  public electricianID = 0;
  public electrician: User;
  public designerID: number;
  public designer: User;
  public paymentType = -1;
  public paymentName = '';
  public paymentEFT = -1;
  public paymentBSB = '';
  public paymentAccountNumber = '';
  public paymentAccountName = '';
  public paymentPersonalUse = -1;
  public paymentBusinessName = '';
  public paymentABN = '';
  public paymentRECs = 0;
  public paymentPrice = 0.00;
  public paymentReceivedDate: Moment;
  public readTermsAndConditions = 0;
  public connectedStandards = 0;
  public standAloneStandards = 0;
  public ownerSignedName = '';
  public ownerSignedDate: Moment;
  public ownerSignatureSerial = 0;
  public ownerSignature = '';
  public ownerSignedGPS: string;
  public ownerPhotoSerial = 0;
  public ownerPhoto = '';
  public ownerPhotoGPS: string;
  public ownerSignedDigitally = 0;
  public installerSignedName = '';
  public installerSignedDate: Moment;
  public installerSignatureSerial = 0;
  public installerSignedGPS: string;
  public installerSignature = '';
  public installerPhotoSerial = 0;
  public installerPhoto = '';
  public installerPhotoGPS: string;
  public designerSignedName = '';
  public designerSignedDate: Moment;
  public designerSignatureSerial = 0;
  public designerSignedGPS: string;
  public designerSignature = '';
  public witnessSignedName = '';
  public witnessSignedDate: Moment;
  public witnessSignatureSerial: number;
  public witnessSignature = '';
  public searchDetails: string;
  public reductionType = -1;
  public pricePerSTC = 0.00;
  public priceFix = 0.00;
  public includeGST = 0;
  public priceFixIncGST = 0.00;
  public priceSTCIncGST = 0.00;
  public authorizedSignedName = '';
  public authorizedSignedPosition: string;
  public trusteeName: string;
  public exportCSVClientSts: number;
  public compliancePlateSerial = 0;
  public inverterCompliancePlateSerial = 0;
  public MYOBUpdatedDate: Date;
  public accountManager = 0;
  public nationalMeteringIdentifier: string;
  public batteryAggregatedControl = -1;
  public batteryIntallerChanged = -1;
  public numberBatteries = 0;
  public sameBatteries = -1;
  public samePanels = -1;
  public differentPanels = 0;
  public type: number = ASSIGNMENT_TYPE_PVD;
  public createdByType: number;
  public photoName: number;
  public installStateName = '';
  public systemModelFireRated: number;
  public serialNumbersInfo: Array<SerialNumbersInfo>;
  public serialNumbers: Array<string>;
  public isolators: Array<string>;
  public batteries: Array<Battery> = [];
  public expManufacturerBatteries: Array<number>;
  public expModelBatteries: Array<number>;
  public additionalPhotos: Array<additionalPhoto>;
  public panels: Array<Panel> = [];
  public removePanels: Array<Panel> = [];
  public removeBatteries: Array<Battery> = [];
  public inverters: Array<Inverter> = [];
  public removeInverters: Array<Inverter> = [];
  public modelType: number = -1;
  //INSTALLER
  public installerFirstName = '';
  public installerLastName = '';
  public installerAccreditation = '';
  public installerUnitType = -1;
  public installerUnitNumber = '';
  public installerStreetNumber = '';
  public installerStreetName = '';
  public installerStreetType = 0;
  public installerSuburb = '';
  public installerState = 0;
  public installerPostcode = '';
  public installerLicence = '';
  public installerPhone = '';
  //DESIGNER
  public designerFirstName = '';
  public designerLastName = '';
  public designerAccreditation = '';
  public designerUnitType = -1;
  public designerUnitNumber = '';
  public designerStreetNumber = '';
  public designerStreetName = '';
  public designerStreetType = 0;
  public designerSuburb = '';
  public designerState = 0;
  public designerPostcode = '';
  public designerLicence = '';
  public designerPhone = '';
  //ELECTRICIAN
  public electricianFirstName = '';
  public electricianLastName = '';
  public electricianUnitType = -1;
  public electricianUnitNumber = '';
  public electricianStreetNumber = '';
  public electricianStreetName = '';
  public electricianStreetType = 0;
  public electricianSuburb = '';
  public electricianState = 0;
  public electricianPostcode = '';
  public electricianLicence = '';
  public electricianPhone = '';

  public invalidPanel = false;
  public invalidInverter = false;
  public invalidInverter2 = false;
  public editableAssignment = false;
  public isStaff = false;
  public errors: Array<FormtrackError>;
  public isValidForm: boolean;
  public paymentFormValid = false;
  public addressFormValid = false;

  //VEEC/ESS
  public claimingESCorVEEC: boolean = false;
  public paymentESCorVEEC = 0;
  public numberESCorVEEC = 0;
  public isColdroomActivity: boolean = false;

  //Electricity changes
  public isHEEREligible: boolean = false;
  public electricityBill = '';
  public electricityBillValue = 0;
  public electricityBillMonthRange = 1;

  get ownerFullName(): string {
    if (this.ownerType == OWNERTYPE_INDIVIDUAL_ID) {
      return this.ownerFirstName + ' ' + this.ownerLastName;
    }
    return this.ownerOrganisationName;
  }

  private _installationAddress: string;
  get fullInstallationAddress() {
    return this._installationAddress ? this._installationAddress : this.formatInstallationAddress();
  };

  deserialize(input: any) {
    Object.assign(this, input);
    this.ownerEmail = input.ownerEmail;
    return this;
  }

  init(input: any) {
    Object.assign(this, input);
    return this;
  }

  constructor(status: number = 0, item?: PVDAssignmentItem) {
    this.status = status;
    this.serialNumbers = new Array<string>();
  }

  formatInstallationAddress(): string {
    var unit = '';
    var street = '';
    var suburb = '';
    var postcode = '';
    var stateAbbreviation = '';
    var unitType = '';
    this._installationAddress = ''
    if (this.installUnitType && 0 < this.installUnitType && this.installUnitType <= STREETTYPE.length) {
      unitType = UNITTYPE.filter(aUnitType => aUnitType.id == this.installUnitType)[0].name;
    }
    unit = unitType + ' ' + this.installUnitNumber;
    if (this.installStreetType && 0 < this.installStreetType && this.installStreetType <= STREETTYPE.length) {
      let streetType = STREETTYPE.filter(aStreetType => aStreetType.id == this.installStreetType)[0].name;
      street = this.installStreetNumber + ' ' + this.installStreetName + ' ' + streetType;
    }
    suburb = this.installSuburb;
    if (this.installState && this.installState > 0) {
      stateAbbreviation = AU_STATES_WITH_SHORTNAME.filter(aState => aState.id == this.installState)[0].abbreviation;
    }
    postcode = this.installPostcode;
    if (unit && unit.trim().length > 0) {
      this._installationAddress = unit + ', ';
    }
    if (street && street.trim().length > 0) {
      this._installationAddress += street + ', ';
    }
    if (suburb && suburb.trim().length > 0) {
      this._installationAddress += suburb + ', ';
    }
    if (stateAbbreviation && stateAbbreviation.trim().length > 0) {
      this._installationAddress += stateAbbreviation + ', ';
    }
    this._installationAddress = this._installationAddress += postcode;
    return this._installationAddress;
  }
}

export class PVDHistory {
  public historySerial: string;
  public description: string;
  public createdBy: string;
  public createdDate: string;
  public createdDateFormatted: string;
}

export class PVDNote {
  public noteSerial: number;
  public noteTypeSerial: number;
  public noteTypeName: string;
  public sectionSerial: number;
  public recordSerial: number;
  public typeId: number;
  public noteTitle: string;
  public noteText: string;
  public createdBy: number;
  public createdByName: string;
  public createdByNameNice: string;
  public createdDate: number;
  public lastModifiedBy: number;
  public lastModifiedByName: string;
  public lastModifiedDate: number;
  public isDeleted: number;
  public deletedBy: number;
  public deletedByName: string;
  public deletedDate: number;
  public thumbPath: string;
  public guestEnabled: number;
  public properties: NoteProperties;
  public createdDateFormatted: string;
}

export class NoteProperties {
  public noteSerial: boolean;
  public noteTypeSerial: boolean;
  public sectionSerial: boolean;
  public recordSerial: boolean;
  public typeId: boolean;
  public noteTitle: boolean;
  public noteText: boolean;
  public createdBy: boolean;
  public createdByName: boolean;
  public createdByNameNice: boolean;
  public createdDate: boolean;
  public lastModifiedBy: boolean;
  public lastModifiedDate: boolean;
  public isDeleted: boolean;
  public guestEnabled: boolean;
}

export class Battery {
  public id: number;
  public assignmentSerial: number;
  public storageManufactured: string;
  public storageModel: string;
  public statusBattery: string;

  constructor(id: number, assignmentSerial: number, storageManufactured: string, storageModel: string, statusBattery: string) {
    this.id = id;
    this.assignmentSerial = assignmentSerial;
    this.storageManufactured = storageManufactured;
    this.storageModel = storageModel;
    this.statusBattery = statusBattery;
  }
}
export class Panel {
  public id: number;
  public assignmentSerial: number;
  public brand: string;
  public model: string;
  public supplier: string;
  public capacity: number;
  public numberPanels: number;
  public wattsPerPanel: number;
  public statusPanel: string;
  public messageStatus: string;

  // tslint:disable-next-line: max-line-length
  constructor(id: number, assignmentSerial: number, brand: string, model: string, supplier: string, capacity: number, numberPanels: number, wattsPerPanel: number, statusPanel: string, messageStatus: string) {
    this.id = id;
    this.assignmentSerial = assignmentSerial;
    this.brand = brand;
    this.model = model;
    this.supplier = supplier;
    this.capacity = capacity;
    this.numberPanels = numberPanels;
    this.wattsPerPanel = wattsPerPanel;
    this.statusPanel = statusPanel;
    this.messageStatus = messageStatus;
  }
}

export class Inverter {
  public id: number;
  public assignmentSerial: number;
  public inverterBrand: string;
  public inverterSeries: string;
  public inverterModel: string;
  public statusInverter: string;
  public messageStatus: string;

  constructor(id: number, assignmentSerial: number, inverterBrand: string, inverterSeries: string, inverterModel: string, statusInverter: string, messageStatus: string) {
    this.id = id;
    this.assignmentSerial = assignmentSerial;
    this.inverterBrand = inverterBrand;
    this.inverterSeries = inverterSeries;
    this.inverterModel = inverterModel;
    this.statusInverter = statusInverter;
    this.messageStatus = messageStatus;
  }
}

export class additionalPhoto {
  public additionalPhotoSerial: number;
  public deleted: number;
  public assignmentSerial: number;
  public assignmentType: number;
  public photoSerial: number;
  public photoType: number;
  public photoDateTime: Date;
}

export class PanelModel {
  brandSerial: number;
  capacity: number;
  fireRated: number;
  modelName: string;
  modelSerial: number;
  modelType: number;
  numberPanels: number;
  seriesSerial: number;
  wattsPerPanel: number;
}
export class PanelBrandModel {
  brandSerial: number;
  brandName: string;
  type: number;
}
export class InverterSeriesModel {
  seriesSerial: number;
  seriesName: string;
  brandSerial: number;
}
export class InverterModel {
  modelSerial: number;
  modelType: number;
  fireRated: number;
  modelName: string;
  numberPanels: number;
  wattsPerPanel: number;
  capacity: number;
  brandSerial: number;
  seriesSerial: number;
}

export class RespSuppModel {
  modelSerial: number;
  cecApprovedDate: Date;
  fireTested: number;
  expiryDate: Date;
  responsibleSupplier1: string;
  responsibleSupplier1ABN: string;
  responsibleSupplier2: string;
  responsibleSupplier2ABN: string;
  responsibleSupplier3: string;
  responsibleSupplier3ABN: string;
}

export class AddressModel {
  suburbSerial: number;
  stateSerial: number;
  stateName: string;
  postcode: string;
  suburbName: string;
  location: string;
}

export class InstallerModel {
  personSerial: number;
  recordTypeSerial: number;
  honorific: string;
  alias: string;
  givenName: string;
  surname: string;
  otherNames: string;
  timeZone: string;
  email: string;
  phone: string;
  phone2: string;
  fax: string;
  mobile: string;
  unitNumber: string;
  streetNumber: string;
  streetName: string;
  suburb: string;
  stateSerial: number;
  postcode: string;
  countrySerial: number;
  createdBy: number;
  createdDate: Date;
  lastModifiedBy: number;
  lastModifiedDate: Date;
  deleted: number;
  deletedBy: number;
  deletedDate: Date;
  ivtUser: number;
  name: string;
  groupName: string;
  displayName: string;
}




export class ElectricianModel {
  electricianID: number;
  electricianFirstName: string;
  electricianLastName: string;
  electricianLicence: string;
  electricianUnitType: number;
  electricianUnitNumber: string;
  electricianStreetNumber: string;
  electricianStreetName: string;
  electricianStreetType: number;
  electricianSuburb: string;
  electricianPostcode: string;
  electricianState: number;
  electricianStateName: string;
  electricianPhone: string;
}

export class AccountManagerModel {
  personSerial: number;
  givenName: string;
}

export class SettingsModel {
  id: number;
  createdBy: number;
  createdDate: Date;
  uploadLimit: number;
  termsAndConditions: string;
  installerStatement: string;
  swhInstallerStatement: string;
  designerTerms: string;
  updatedDate: Date;
  nextUploadId: number;
  installerGroupSerial: number;
  installLimit: number;
  maxRecPrice: number;
  completePageSize: number;
  defaultFileSerial: number;
  additionalPhoto1Name: string;
  additionalPhoto2Name: string;
  additionalPhoto3Name: string;
  additionalPhoto4Name: string;
  powerPointsPerSTC: number;
  defaultFileName: string;
  veecInstallerDeclaration: string;
  veecConsumerDeclaration: string;
  veecConsumerRightsDeclaration: string;
  coldroomInstallerDeclaration: string;
  coldroomConsumerDeclaration: string;
  escEnergyServerDeclaration: string;
  stcTermsAndConditions: string;
  stcOwnerDeclaration: string;
  stcTermsAndConditionsCustom: string;
}

export class SignatureModel {

  signedName: string;
  signedDate: Moment;
  validForm: boolean;
  constructor(signedName: string = '', signedDate: Moment = null, validForm = false) {
    this.signedName = signedName;
    this.signedDate = signedDate;
    this.validForm = validForm;
  }
}

export class FileModel {
  serial: number;
  name: string;
  constructor(serial: number = 0, name: string = '') {
    this.serial = serial;
    this.name = name;
  }
}

export class SerialNumbersInfo {
  public serialNumber: string;
  public duplicate: boolean;
  public verified: boolean;

  constructor(serialNumber: string = '', duplicate: boolean = false, verified: boolean = false) {
    this.serialNumber = serialNumber;
    this.duplicate = duplicate;
    this.verified = verified;
  }
}
