import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Credential } from '../../modules/shared/models/user';

import { Router } from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  public hide: boolean = true;
  public credential: Credential;
  

  constructor(public _auth: AuthService, public router: Router) {
    this.credential = new Credential();
  }

  ngOnInit() {
    this._auth.loading = false;
  }

  login() {
    this._auth.loading = true;
    this._auth.submitLoginInfo(this.credential);
  }
}
