import Bugsnag from '@bugsnag/js';
// import BugsnagPerformance from '@bugsnag/browser-performance';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import {
  MatToolbarModule, MatMenuModule, MatButtonModule, MatTabsModule, MatCardModule, MatFormFieldModule, MatInputModule, MatCheckboxModule, MatSnackBarModule,
  MatProgressBarModule, MatDialog, MatDialogModule, MAT_RIPPLE_GLOBAL_OPTIONS, MatSelectModule
} from '@angular/material';
import { MatIconModule } from "@angular/material/icon";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthComponent } from './components/auth/auth.component';
import { SignatureFormComponent } from './components/signature-form/signature-form.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PostcodeActionButtonsComponent } from './modules/control-centre/components/postcode-action-buttons/postcode-action-buttons.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BUGSNAG_API_KEY, environment } from '../environments/environment';
import { ButtonsComponent } from './modules/assignments/components/buttons/buttons.component';
import { ReadytosubmitWidgetComponent } from './modules/assignments/components/readytosubmit-widget/readytosubmit-widget.component';
import { ReadytosubmitDialogComponent } from './modules/assignments/components/readytosubmit-dialog/readytosubmit-dialog.component';
import { ConfirmationDialogComponent } from './modules/shared/components/confirmation-dialog/confirmation-dialog.component';
import { InductionFormComponent } from './components/induction-form/induction-form.component';

Bugsnag.start({ apiKey: BUGSNAG_API_KEY });
// BugsnagPerformance.start({ apiKey: '55765620bf7b51673133674632f218ca' })
// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

@NgModule({
  declarations: [
    PostcodeActionButtonsComponent,
    AppComponent,
    FooterComponent,
    AuthComponent,
    PageNotFoundComponent,
    ButtonsComponent,
    ReadytosubmitWidgetComponent,
    ReadytosubmitDialogComponent,
    SignatureFormComponent,
    ConfirmationDialogComponent,
    InductionFormComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatSnackBarModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ReactiveFormsModule,
    MatSelectModule
  ],
  providers: [
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AuthService,
    AuthGuard,
    MatDialog,
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } }
  ],
  bootstrap: [AppComponent],
  entryComponents: [PostcodeActionButtonsComponent, ButtonsComponent, ReadytosubmitDialogComponent, ReadytosubmitWidgetComponent, ConfirmationDialogComponent]
})
export class AppModule { }
