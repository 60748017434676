import { Component } from '@angular/core';
import { AssignmentsService } from '../../services/assignments.service';
import { STATUS_ASSIGNMENT_READYTOSUBMIT_NAME } from 'src/environments/constants';

@Component({
  selector: 'app-readytosubmit-widget',
  template: `
  <div [class.ready-to-submit]="statusName == readyStatus" [class.text-cell]="statusName != readyStatus" (click)="submit()">
    <div [style.color]="statusColour">
      <span *ngIf="value == 15"><i class="material-icons grey">announcement</i></span>
      <span *ngIf="value != 15">{{statusName}}</span>
    </div>
  </div>
  `,
  styleUrls: ['./readytosubmit-widget.component.scss']
})
export class ReadytosubmitWidgetComponent {

  constructor( private _as: AssignmentsService  ) { }

  public readyStatus = STATUS_ASSIGNMENT_READYTOSUBMIT_NAME;

  public serial: number;
  public type: number;
  public statusColour: any;
  public statusName: any;
  public value: any = 0;

  submit() {
    if (this.statusName == STATUS_ASSIGNMENT_READYTOSUBMIT_NAME)
      this._as.readyToSubmit(this.serial, this.type)
  }
}
