export class ImportModelResponse {
    public importModel: Array<ImportModel>;

}
export class ImportModel {
    public profileSerial: number;
    public profileName:string;
    public profileType:number;
    public contentType:number;
    public fileType: string;
    public duplicateRecord:string;
    public duplicateFields:Array<string>;
    public sectionSerial:number;
    public fieldMappings:Array<FieldMappingsModel>;
}

export class FieldMappingsModel{
    public name:number;
    public label:string;
    public description:string;
    public fieldType:string;
    public mappedFieldName: string;
}
